import React, { useState, useEffect, useCallback, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { Form, Button, Modal, Table } from 'react-bootstrap';
import { FaEdit, FaTrash } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { viewSalarySetup, editSalarySetup, updateSalarySetup } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Editsalarysetup = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    basic: [],
    deductions: [],
    allowances: []
  });
  const [masterId, setMasterId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fetchedMasterId, setFetchedMasterId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("add");
  const [selectedType, setSelectedType] = useState("");
  const [newItem, setNewItem] = useState({ name: "", deduct_type: "", type: '' });
  const [editItemDetails, setEditItemDetails] = useState({
    name: '',
    type: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [deleteType, setDeleteType] = useState("Allowance");
  const [selectedItem, setSelectedItem] = useState(null);
  const [errors, setErrors] = useState({});

  const fetchSalaryData = async () => {
    try {
      setLoading(true);
      const responseData = await viewSalarySetup(id);
      const newMasterId = responseData.data[0]._id;
      setMasterId(newMasterId);
      setFetchedMasterId(true);
      setLoading(false);
    }
    catch (error) {

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSalaryData = _debounce(fetchSalaryData, 100);

  useEffect(() => {
    debouncedFetchSalaryData();
    return debouncedFetchSalaryData.cancel;
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseData = await editSalarySetup(id, masterId);
        const { success } = responseData;


        if (success) {
          setFormData({
            name: success.name,
            basic: success.basic || [],
            deductions: success.deduction || [],
            allowances: success.allowence || []
          });

          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (id && fetchedMasterId) {
      fetchData();
    }
  }, [id, masterId, fetchedMasterId]);


  const handleShowModal = (action, name, deduct_type, type) => {
    console.log(selectedType);

    setModalAction(action);
    setEditItemDetails({ name, type });

    if (type) {
      setSelectedType(type);
    }

    console.log(selectedItem);

    if (name && deduct_type && type) {
      setNewItem({ name, deduct_type, type });
      setSelectedItem({ name, deduct_type, type });
    } else {
      setNewItem({ name: '', deduct_type: "", type: '' });
      setSelectedType('');
    }

    setShowModal(true);
  };

  const handleCloseModal = () => {
    console.log(newItem);
    console.log(selectedType);
    setSelectedType('');

    setShowModal(false);
    // Reset the newItem state and editItemDetails state
    setEditItemDetails({ name: '', type: '' });
    setNewItem({ name: '', deduct_type: '', type: '' });
  };




  const handleModalSave = () => {

    const newErrors = {};

    // Validate required fields
    if (!selectedType) newErrors.selectedType = 'Please select a type.';
    if (!newItem.name) newErrors.name = 'Name is required.';
    if (!newItem.deduct_type) newErrors.deduct_type = 'Deduct type is required.';

    // Update errors state
    setErrors(newErrors);

    // If no errors, proceed with saving
    if (Object.keys(newErrors).length === 0) {
      // Your save logic here
      console.log('Saving:', { selectedType, newItem });
      handleCloseModal();
    }

    if (modalAction == "add") {
      // Create a new item based on the selected type
      const newItemData = {
        name: newItem.name,
        deduct_type: newItem.deduct_type
      };

      // Update the formData state
      if (selectedType === "Allowance") {
        setFormData((prevData) => ({
          ...prevData,
          allowances: [...prevData.allowances, newItemData]
        }));
      } else if (selectedType === "Deduction") {
        setFormData((prevData) => ({
          ...prevData,
          deductions: [...prevData.deductions, newItemData]
        }));
      }
    } else if (modalAction == "edit") {
      // Update the existing item
      if (selectedItem.type == "Allowance") {
        setFormData((prevData) => ({
          ...prevData,
          allowances: prevData.allowances.map((item) =>
            item.name == selectedItem.name ? { ...item, deduct_type: selectedItem.deduct_type } : item
          )
        }));

      } else if (selectedItem.type == "Deduction") {
        setFormData((prevData) => ({
          ...prevData,
          deductions: prevData.deductions.map((item) =>
            item.name == selectedItem.name ? { ...item, deduct_type: selectedItem.deduct_type } : item
          )
        }));
      }
    }



    // Reset the newItem and selectedItem state
    setNewItem({ name: "", deduct_type: "" });
    setSelectedItem({ name: "", deduct_type: "", type: "" });

    // Close the modal
    // handleCloseModal();
  };


  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
    setDeleteItemName(""); // Reset item name
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteModalSave = () => {

    const deleteErrors = {};

    // Validate required fields
    if (!deleteType) deleteErrors.deleteType = 'Please select a type.';
    if (!deleteItemName) deleteErrors.deleteItemName = 'Please select an item to delete.';

    // Update errors state
    setErrors(deleteErrors);

    // If no errors, proceed with deleting
    if (Object.keys(deleteErrors).length === 0) {
      // Your delete logic here
      console.log('Deleting:', deleteItemName);
      handleCloseDeleteModal();
    }

    // Check if trying to delete a default item
    const isDefault =
      (deleteType == "Allowance" && formData.allowances.some(item => item.name == deleteItemName && item.default == true)) ||
      (deleteType == "Deduction" && formData.deductions.some(item => item.name == deleteItemName && item.default == true));

    if (isDefault) {
      toast.error("You cannot remove default values!"); // Show error toast if trying to delete default values
    } else {
      
      if (deleteType == "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowances: prevState.allowances.filter(item => item.name != deleteItemName),
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          deductions: prevState.deductions.filter(item => item.name != deleteItemName),
        }));
      }
      toast.success(`${deleteItemName} successfully deleted!`); // Show success toast
      // setShowDeleteModal(false);
    }
  };

  const handleUpdateSalaryStructure = async (e) => {
    console.log(formData);

    e.preventDefault();
    try {
      setLoading(true);
      const response = await updateSalarySetup(id, masterId, formData);
      // Handle the response as needed, e.g., show a success message
      console.log('Salary setup updated successfully:', response);

      if (response) {
        toast.success('Salary Structure updated successfully', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error updating salary setup:', error);
      // Handle the error, e.g., show an error message
    } finally {
      setLoading(false);
    }
    navigate("/admin/Viewempsalary");
  }

  return (
    <React.Fragment>
      <div className="main p-4 p-lg-5 mt-5">
        <h3 className="mb-4">Edit Salary Structure</h3>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/admin/Viewempsalary">Salary Structure</Link>
            </li>
          </ol>
          <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
            </svg>
            Back
          </Link>
        </div>

        <Button variant="primary" onClick={() => handleShowModal("add")} className="me-2">
          Add Salary Name
        </Button>
        <Button variant="danger" onClick={handleShowDeleteModal} className="me-2">
          Remove Salary Name
        </Button>

        <Form>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Group>


          <Table bordered hover className="table table-striped mt-3">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Sr No.</th>
                <th>Type</th>
                <th>Name</th>
                <th>Add/Deduct Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {formData?.basic?.length > 0 && formData.basic.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>Basic</td>
                  <td>{item.name}</td>
                  <td>{item.deduct_type}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Basic")}
                    >
                      <FaEdit color="blue" />
                    </Button>
                  </td>
                </tr>
              ))}

              {formData?.allowances?.length > 0 && formData.allowances.map((item, index) => (
                <tr key={`allowance-${index}`}>
                  <td>{formData.basic.length + index + 1}</td>
                  <td>Allowance</td>
                  <td>{item.name}</td>
                  <td>{item.deduct_type}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Allowance")}
                    >
                      <FaEdit color="blue" />
                    </Button>
                  </td>
                </tr>
              ))}

              {formData?.deductions?.length > 0 && formData.deductions.map((item, index) => (
                <tr key={`deduction-${index}`}>
                  <td>{formData.basic.length + formData.allowances.length + index + 1}</td>
                  <td>Deduction</td>
                  <td>{item.name}</td>
                  <td>{item.deduct_type}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Deduction")}
                    >
                      <FaEdit color="blue" />
                    </Button>
                  </td>
                </tr>
              ))}

              {formData.basic.length === 0 && formData.allowances.length === 0 && formData.deductions.length === 0 && (
                <tr>
                  <td colSpan="5">No items available</td>
                </tr>
              )}
            </tbody>
          </Table>



          <Button variant="primary" type="button" onClick={handleUpdateSalaryStructure} className="mt-3">
            Update Salary Structure
          </Button>
        </Form>

        {/* <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAction === "add" ? "Add Allowance/Deduction" : "Edit Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction === "add" && (
              <Form.Group>
                <Form.Label>Select Type</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedType}
                  required
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    setNewItem({ ...newItem, type: e.target.value });
                  }}
                >
                  <option value="" disabled>Select</option>
                  <option value="Allowance">Allowance</option>
                  <option value="Deduction">Deduction</option>
                </Form.Control>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                readOnly={modalAction === "edit"}
                value={modalAction === "edit" ? selectedItem.name : newItem.name}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction === "edit") {
                    setSelectedItem({ ...selectedItem, name: value });
                  } else {
                    setNewItem({ ...newItem, name: value });
                  }
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Add/Deduct Type</Form.Label>
              <Form.Control
                as="select"
                value={modalAction === "edit" ? selectedItem.deduct_type : newItem.deduct_type}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction === "edit") {
                    setSelectedItem({ ...selectedItem, deduct_type: value });
                  } else {
                    setNewItem({ ...newItem, deduct_type: value });
                  }
                }}
              >
                <option value="" disabled>Select</option>
                <option value="Fixed">Fixed</option>
                <option value="Percentage">Percentage</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Type</Form.Label>
              <Form.Control
                as="select"
                value={deleteType}
                onChange={(e) => {
                  setDeleteType(e.target.value);
                  setDeleteItemName(""); // Reset the selected item when type changes
                }}
              >
                <option value="Allowance">Allowance</option>
                <option value="Deduction">Deduction</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Item to Delete</Form.Label>
              <Form.Control
                as="select"
                value={deleteItemName}
                onChange={(e) => setDeleteItemName(e.target.value)}
              >
                <option value="">Select Item</option>
                {(deleteType === "Allowance"
                  ? formData.allowances.filter(item => item.default == false)
                  : formData.deductions.filter(item => item.default == false)
                ).map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteModalSave}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal> */}


        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAction === "add" ? "Add Allowance/Deduction" : "Edit Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction === "add" && (
              <Form.Group>
                <Form.Label>Select Type</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedType}
                  isInvalid={!!errors.selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    setNewItem({ ...newItem, type: e.target.value });
                    setErrors((prev) => ({ ...prev, selectedType: null })); // Clear error
                  }}
                >
                  <option value="" disabled>Select</option>
                  <option value="Allowance">Allowance</option>
                  <option value="Deduction">Deduction</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.selectedType}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.name}
                value={modalAction === "edit" ? selectedItem.name : newItem.name}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction === "edit") {
                    setSelectedItem({ ...selectedItem, name: value });
                  } else {
                    setNewItem({ ...newItem, name: value });
                  }
                  setErrors((prev) => ({ ...prev, name: null })); // Clear error
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Add/Deduct Type</Form.Label>
              <Form.Control
                as="select"
                isInvalid={!!errors.deduct_type}
                value={modalAction === "edit" ? selectedItem.deduct_type : newItem.deduct_type}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction === "edit") {
                    setSelectedItem({ ...selectedItem, deduct_type: value });
                  } else {
                    setNewItem({ ...newItem, deduct_type: value });
                  }
                  setErrors((prev) => ({ ...prev, deduct_type: null })); // Clear error
                }}
              >
                <option value="">Select Type</option>
                <option value="Fixed">Fixed</option>
                <option value="Percentage">Percentage</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deduct_type}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Type</Form.Label>
              <Form.Control
                as="select"
                value={deleteType}
                isInvalid={!!errors.deleteType}
                onChange={(e) => {
                  setDeleteType(e.target.value);
                  setDeleteItemName(""); // Reset the selected item when type changes
                  setErrors((prev) => ({ ...prev, deleteType: null })); // Clear error
                }}
              >
                <option value="Allowance">Allowance</option>
                <option value="Deduction">Deduction</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deleteType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Item to Delete</Form.Label>
              <Form.Control
                as="select"
                value={deleteItemName}
                isInvalid={!!errors.deleteItemName}
                onChange={(e) => {
                  setDeleteItemName(e.target.value);
                  setErrors((prev) => ({ ...prev, deleteItemName: null })); // Clear error
                }}
              >
                <option value="">Select Item</option>
                {(deleteType === "Allowance"
                  ? formData.allowances.filter(item => !item.default)
                  : formData.deductions.filter(item => !item.default)
                ).map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deleteItemName}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteModalSave}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default Editsalarysetup;
