
// Base Url
// export const BASE_URL = "https://my.windsonpayroll.com/API/public/api";
export const BASE_URL = "https://staging.windsonpayroll.com/API/public/api";

export const getToken = () => {
  const token = sessionStorage.getItem('token');

  return token ? `Bearer ${token}` : '';


};



export const getId = () => {
  const id = sessionStorage.getItem('id')
  return id ? id : '';
}
const emp = getId()
console.log(emp)



// export const getcompanyId = () => {
//   const companyID = sessionStorage.getItem('companyID');
//   return companyID ? companyID : '';
// };
// const companyid = getcompanyId()
// console.log(companyid)



const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};



// company_register Api end point
export const registerCompany = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/company_register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};



// company_login Api end point
export const companyLogin = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/company_login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //  "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);

    // Log the response body
    const responseBody = await response.text();
    console.log("Response Body:", responseBody);
    return responseBody;

  } catch (error) {
    // const data = await handleErrors(response);
    // return data;
    // throw Error("An error occurred while processing the request.");
  }
};

// add_user Api end Point
// export const addUser = async (formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_user`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         //  "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify(formData),
//     });
//     console.log("Response Status:", response.status);
//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);
//     return responseBody;
//   } catch (error) {
//     throw Error("An error occurred while processing the request.");
//   }
// };
// export const addUser = async (formData, branch_id) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_user`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//         // Other headers if needed
//       },
//       body: JSON.stringify({
//         ...formData, // Include form data here if available
//         "branch_id": parseInt(branch_id), // Include the branch_id parameter
//       }),
//     });

//     console.log("Response Status:", response.status);
//     const responseBody = await response.json();
//     console.log("Response Body:", responseBody);
//     return responseBody;
//   } catch (error) {
//     throw Error("An error occurred while processing the request.");
//   }
// };

export const addUser = async (formData) => {
  try {

    const response = await fetch(`${BASE_URL}/add_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);
    const responseBody = await response.text();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};

export const basicSetup = async (formData) => {
  try {

    const response = await fetch(`${BASE_URL}/basicSetup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};

export const getBasicSetup = async () => {
  try {

    const response = await fetch(`${BASE_URL}/getBasicSetup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
    });
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};



// view_user Api end ponit

export const viewUser = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/view_user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      // No need to include the body in a GET request
      // body: JSON.stringify(formData), // Remove this line
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};


export const editUser = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id) }),
    });

    console.log("id get", id);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");

  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ ...userData, "id": parseInt(id) }), // Include 'id' in userData
    });

    if (!response.ok) {
      throw new Error("Failed to update user.");
    }

    const responseData = await response.json();
    return responseData; // Return the response data
  } catch (error) {
    // Handle error appropriately, throw an error or log it
    console.error("Error updating user:", error);
    return null; // Return null or handle the error as needed
  }
};



// Delete User

export const deleteUser = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id) }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete user.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting user:", error);
    return null;
  }
};






export const submitForgetPasswordForm = async (email) => {
  console.log(email);

  try {
    const response = await fetch(`${BASE_URL}/submitForgetPasswordForm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ email: email }),
    });

    console.log(response);




    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error Sending Email:", error);
    return null;
  }
};

export const updatepassword = async (email, password) => {
  console.log(email);

  try {
    const response = await fetch(`${BASE_URL}/update_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ email, password }),
    });

    console.log(response);

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error Sending Email:", error);
    return null;
  }
};





// view_companyuser Api end Point

export const viewCompanyUsers = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_companyuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "Authorization": token,
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};



//  Add employee
export const addEmployee = async (formData) => {
  try {

    const response = await fetch(`${BASE_URL}/add_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};
// export const addEmployeeAddImage = async (id) => {
//   try {

//     const response = await fetch(`http://nbphr.veravalonline.com:5000/add_image`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ "_id": id }),
//     });
//     console.log("Response:", response);
//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);
//     // return responseBody;
//   } catch (error) {
//     throw Error("An error occurred while processing the request.");
//   }
// };






// viewEmployee Api end ponit

// export const viewEmployee = async (formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/view_employee`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//     });

//     if (!response.ok) {
//       throw new Error("Failed to fetch data.");
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     throw new Error("An error occurred while processing the request.");
//   }
// };

// export const addEmployeeAddImage = async (id) => {
//   try {
//     const token = getToken(); // Assuming getToken() returns a valid token

//     const response = await fetch(`https://cors-anywhere.herokuapp.com/http://nbphr.veravalonline.com:5000/add_image`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ _id: id }),
//     });

//     console.log("Response:", response);

//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);

//     return responseBody; // Return the response body if needed
//   } catch (error) {
//     console.error("An error occurred while processing the request:", error);
//     throw Error("An error occurred while processing the request.");
//   }
// };

// export const addEmployeeAddImage = async (intId) => {
//   try {
//     const token = getToken();

//     // const response = await fetch(`/api/add_image`, {
//     console.log(intId, typeof intId);
//     const formData = new FormData();
//     formData.append('_id', intId);
//     console.log(formData);


//     const response = await fetch(`http://api.windsonpayroll.com:5000/add_image`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//       },

//       // body: JSON.stringify({ '_id': intId }),
//       body: JSON.stringify(formData),
//     });

//     console.log("Response:", response);

//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);

//     return responseBody; // Return the response body if needed
//   } catch (error) {
//     console.error("An error occurred while processing the request:", error);
//     throw Error("An error occurred while processing the request.");
//   }
// };

export const addEmployeeAddImage = async (intId) => {
  try {
    const formData = new FormData();
    formData.append('_id', intId); // Append _id to the FormData object

    const response = await fetch(`http://api.windsonpayroll.com:5000/add_image`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ '_id': intId }), // Send the FormData object directly

    });

    const responseBody = await response.text();
    console.log("Response Body:", responseBody);

    // return responseBody;
  } catch (error) {
    console.error("An error occurred while processing the request:", error);
    throw Error("An error occurred while processing the request.");
  }
};



export const viewEmployee = async (formData, location) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...formData,
        location: location,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    console.log(response);
    console.log(formData);
    console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};


export const employeehistorypaggination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/employee_history_paggination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};
export const viewemployeepaggination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_paggination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};
export const viewdesignationpagination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_designation_pagination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};

export const viewdepartmentpagination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_department_pagination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};
export const viewholidaypagination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_holiday_pagination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};
export const viewresignationpaggination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_resignation_paggination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);



    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};

export const viewsalarysetuppagination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_salary_setup_pagination`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page, pageSize: pageSize

      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    // console.log(formData);
    // console.log(location);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};





export const editEmployee = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id) }),
    });

    console.log("id value", id);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {


  }
};





export const updateEmployee = async (id, userData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ ...userData, "id": parseInt(id) }),
    });

    if (!response.ok) {
      throw new Error("Failed to update employee.");
    }

    const responseData = await response.json();
    return responseData; // Return the response data
  } catch (error) {
    // Handle error appropriately, throw an error or log it
    console.error("Error updating employee:", error);
    return null; // Return null or handle the error as needed
  }
};




export const deleteEmployee = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id) }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete employee.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting employee:", error);
    return null;
  }
};





// paginateUsers api end point

export const paginateUsers = async (pageNumber, itemsPerPage) => {
  try {
    const response = await fetch(`${BASE_URL}/paginate_user?page=${pageNumber}&perPage=${itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch paginated user data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while fetching paginated user data.");
  }
};






//Add Designation
//  export const addDesignation = async (formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_designation`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify(formData),
//     });

//     console.log("Response Status:", response.status);



//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);
//     return responseBody;
//   } catch (error) {
//     // console.error("An error occurred while processing the request:", error.message);
//     // throw new Error("An error occurred while processing the request.");
//   }
// };

// //view Desiganation
// export const viewDesignation  = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/view_designation`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // 'Authorization': `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch department data.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };

// //deleteDesignation
// export  const deleteDesignation = async (id, masterId) => {
//   try {
//    const response = await fetch(`${BASE_URL}/delete_designation`, {
//       body: JSON.stringify({
//         id: parseInt(id),
//         masterId: parseInt(masterId),
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to delete department.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error deleting department:', error);
//     return null;
//   }
// };

// export const updateDesignation = async (id,masterId,designationData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/update_designation`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ ...designationData, "id": parseInt(id), "masterId":parseInt(masterId)}), 
//     });

//     if (!response.ok) {
//       throw new Error("Failed to update department.");
//     }

//     const responseData = await response.json();
//     return responseData; 
//   } catch (error) {
//     console.error("Error updating department:", error);
//     return null;
//   }
// };

// //edit designtion
// export const editDesignation = async (id, masterId) => {
//   try {
//     const response = await fetch(`https://my.windsonpayroll.com/API/public/api/edit_designation`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(masterId) }), 
//     });

//     console.log("id get", id);
//     console.log("masterid get", masterId);

//     if (!response.ok) {
//       throw new Error("Failed to fetch data.");
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     // Handle error appropriately, throw an error or log itaddE
//     // throw new Error("An error occurred while processing the request.");
//   }
// };
export const deleteDesignation = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_designation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error('Failed to delete designation.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error deleting designation:', error);
    return null;
  }
};

export const viewDesignation = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_designation`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

// Updated addDesignation function
export const addDesignation = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_designation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log('Response Status:', response.status);
    const responseBody = await response.json();
    console.log('Response Body:', responseBody);
    return responseBody;
  } catch (error) {
    throw Error('An error occurred while processing the request.');
  }
};

// Updated editDesignation function
export const editDesignation = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_designation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'id': parseInt(id), 'masterId': parseInt(masterId) }),
    });

    console.log('id get', id);
    console.log('masterid get', masterId);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error('An error occurred while processing the request.');
  }
};

// Updated updateDesignation function
export const updateDesignation = async (id, masterId, designationData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_designation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        ...designationData,
        'id': parseInt(id),
        'masterId': parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update designation.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating designation:', error);
    return null;
  }
};


//add leave
export const addLeave = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log('Response Status:', response.status);
    const responseBody = await response.text();
    console.log('Response Body:', responseBody);
    return responseBody;
  } catch (error) {
    throw Error('An error occurred while processing the request.');
  }
};

//view leave
export const viewLeave = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_leave`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

//delete Leave
export const deleteLeave = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error('Failed to delete leave.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error deleting leave:', error);
    return null;
  }
};

//edit leave
export const editLeave = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'id': parseInt(id), 'masterId': parseInt(masterId) }),
    });

    console.log('id get', id);
    console.log('masterid get', masterId);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error('An error occurred while processing the request.');
  }
};

//update leave
export const updateLeave = async (id, masterId, leaveData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        ...leaveData,
        'id': parseInt(id),
        'masterId': parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update leave.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating leave:', error);
    return null;
  }
};

//View Department api end point
// export const viewDepartment = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/view_department`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // 'Authorization': `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch department data.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// }; 




// view resignation function 
export const viewResignation = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_resignation`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};
//add resignation function 
export const addResignation = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_resignation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log('Response Status:', response.status);
    const responseBody = await response.text();
    console.log('Response Body:', responseBody);
    return responseBody;
  } catch (error) {
    throw Error('An error occurred while processing the request.');
  }
};
//edit resignation function 
export const editResignation = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_resignation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'id': parseInt(id), 'masterId': parseInt(masterId) }),
    });

    console.log('id get', id);
    console.log('masterid get', masterId);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error('An error occurred while processing the request.');
  }
};

// Updated updateResignation function
export const updateResignation = async (id, masterId, resignationData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_resignation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        ...resignationData,
        'id': parseInt(id),
        'masterId': parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update designation.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating designation:', error);
    return null;
  }
};
//Delete Resignation function
export const deleteResignation = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_resignation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error('Failed to delete resignation.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error deleting resignation:', error);
    return null;
  }
};




// export const addDepartment = async (formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_department`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify(formData),
//     });
//     console.log("Response Status:", response.status);
//     const responseBody = await response.text();
//     console.log("Response Body:", responseBody);
//     return responseBody;
//   } catch (error) {
//     throw Error("An error occurred while processing the request.");
//   }
// };






// export const editDepartment = async (id,masterId) => {
//   try {
//     const response = await fetch(`${BASE_URL}/edit_department`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ "id": parseInt(id) ,"masterId": parseInt(masterId)}), 
//     });

//     console.log("id get",id);
//     console.log("masterid get",masterId);

//     if (!response.ok) {
//       throw new Error("Failed to fetch data.");
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     // Handle error appropriately, throw an error or log it
//     // throw new Error("An error occurred while processing the request.");

//   }
// };

// export const updateDepartment = async (id,masterId,departmentData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/update_department`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({ ...departmentData, "id": parseInt(id), "masterId":parseInt(masterId)}), 
//     });

//     if (!response.ok) {
//       throw new Error("Failed to update department.");
//     }

//     const responseData = await response.json();
//     return responseData; 
//   } catch (error) {
//     console.error("Error updating department:", error);
//     return null;
//   }
// };





// // Delete Department
//  export const deleteDepartment = async (id, masterId) => {
//   try {
//     const response = await fetch(`${BASE_URL}/delete_department`, {
//       body: JSON.stringify({
//         id: parseInt(id),
//         masterId: parseInt(masterId),
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to delete department.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error deleting department:', error);
//     return null;
//   }
// };


export const viewDepartment = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_department`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const addDepartment = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_department`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};

export const editDepartment = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_department`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(masterId) }),
    });

    console.log("id get", id);
    console.log("masterid get", masterId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");
  }
};

export const updateDepartment = async (id, masterId, departmentData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_department`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...departmentData,
        "id": parseInt(id),
        "masterId": parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update department.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating holiday:", error);
    return null;
  }
};

export const deleteDepartment = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_department`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error("Failed to delete department.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting department:", error);
    return null;
  }
};

export const viewHoliday = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_holiday`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};






// viewHoliday Api end ponit





export const deleteHoliday = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_holiday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error("Failed to delete employee.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting employee:", error);
    return null;
  }
};

export const addHoliday = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_holiday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log("Response Status:", response.status);
    const responseBody = await response.text();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};



// editHoliday api end point

export const editHoliday = async (id, masterId) => {
  try {
    const response = await fetch(`https://my.windsonpayroll.com/API/public/api/edit_holiday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(masterId) }),
    });

    console.log("id get", id);
    console.log("masterid get", masterId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");
  }
};


// updateHoliday api end point


export const updateHoliday = async (id, masterId, holidayData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_holiday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...holidayData,
        "id": parseInt(id),
        "masterId": parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update holiday.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating holiday:", error);
    return null;
  }
};



// viewShift api end point

export const viewShift = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_time`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};


// addShift api end point
export const addShift = async (formData) => {
  console.log(formData);

  try {
    const response = await fetch(`${BASE_URL}/add_time`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log(response);

    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};

//  update shift api end point

export const updateShift = async (id, masterId, shiftData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_time`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...shiftData,
        "id": parseInt(id),
        "masterId": parseInt(masterId),

      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update holiday.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating holiday:", error);
    return null;
  }
};





export const editShift = async (id, masterId, shiftNo) => {
  try {
    const response = await fetch(`https://my.windsonpayroll.com/API/public/api/edit_time`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(masterId), "shift_no": parseInt(shiftNo) }),
    });

    console.log("id get", id);
    console.log("masterid get", masterId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }



    const responseData = await response.json();
    console.log(responseData);
    return responseData;


  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");
  }
};




// deleteShift api end point

export const deleteShift = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_time`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error("Failed to delete shift.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting shift:", error);
    return null;
  }
};








export const viewBranch = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_branch`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const viewapplicatestatus = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_applicate_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });


    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const addapplicatestatus = async (status_name) => {
  try {
    const response = await fetch(`${BASE_URL}/add_applicate_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ status_name: status_name }),
    });


    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.message) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const editApplicantStatus = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_applicate_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: id }),
    });


    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const updateApplicantStatus = async (id, status_name) => {
  try {
    const response = await fetch(`${BASE_URL}/update_applicate_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: id, status_name: status_name }),
    });


    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.status) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};


export const deleteBranch = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error("Failed to delete branch.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting branch:", error);
    return null;
  }
};

export const addBranch = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });
    console.log(response);

    console.log("Response Status:", response.status);
    const responseBody = await response.json();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};
// editHoliday api end point

export const editBranch = async (id, masterId) => {
  try {
    const response = await fetch(`https://my.windsonpayroll.com/API/public/api/edit_branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(masterId) }),

    });

    console.log("id get", id);
    console.log("masterid get", masterId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");
  }
};


// updateHoliday api end point


export const updateBranch = async (id, masterId, branchData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...branchData,
        "id": parseInt(id),
        "masterId": parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update branch.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating holiday:", error);
    return null;
  }
};



//add employe leave 
// export const addEmployeeLeave = async (formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_leave`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify(formData),
//     });

//     console.log('Response Status:', response.status);
//     const responseBody = await response.text();
//     console.log('Response Body:', responseBody);
//     return responseBody;
//   } catch (error) {
//     throw Error('An error occurred while processing the request.');
//   }
// };
export const addEmployeeLeave = async (employee_id, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_employee_leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        "employeeId": getId(),
      },
      body: JSON.stringify({
        "employee_id": parseInt(employee_id),
        ...formData, // Include form data here if available
      }),
    });

    console.log('Response Status:', response.status);
    const responseBody = await response.text();
    console.log('Response Body:', responseBody);
    return responseBody;
  } catch (error) {
    throw Error('An error occurred while processing the request.');
  }
};

export const viewAddEmployeeLeave = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_leave`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const deleteAddEmployeeLeave = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_employee_leave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(), // Assuming getToken() retrieves the authorization token
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error("Failed to delete branch.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting branch:", error);
    return null;
  }
};




// export const addEmployeeAttendance = async ({ employee_id, attendance_status }) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_attendance`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//         "employeeId": getId(),
//       },
//       body: JSON.stringify({
//         "employee_id": parseInt(employee_id),
//         "attendance_status": attendance_status
//       }),
//     });

//     if (!response.ok) {
//       throw new Error("Failed to add employee attendance.");
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error("Error adding employee attendance:", error);
//     return null;
//   }
// };




export const viewEmployeehistory = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/employee_history`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};









//  export const viewAnnouncement = async () => {
//    try {
//      const response = await fetch(`${BASE_URL}/view_announcement`, {
//        method: 'GET',
//        headers: {
//          'Content-Type': 'application/json',
//          'Authorization': getToken(),
//        },
//      });

//      if (!response.ok) {
//        throw new Error('Failed to fetch data.');
//      }

//      const responseData = await response.json();

//      if (!responseData.success || !responseData.success.length) {
//        throw new Error('Invalid response format.');
//      }

//      return responseData.success[0].announcement[0]; // Returning the first announcement array
//    } catch (error) {
//      throw new Error('An error occurred while processing the request.');
//    }
//  };

export const viewAnnouncement = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_announcement`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const editAnnouncement = async (id, mastersId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_announcement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`, // Include if necessary
        "Authorization": getToken(), // Make sure to define and provide the getToken function
      },
      body: JSON.stringify({ "id": parseInt(id), "masterId": parseInt(mastersId) }),
    });

    console.log("id get", id);
    console.log("mastersid get", mastersId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error("An error occurred while processing the request.");
  }
};

export const addAnnouncement = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_announcement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log("Response Status:", response.status);
    const responseBody = await response.text();
    console.log("Response Body:", responseBody);
    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};

export const deleteAnnouncement = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_announcement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete announcement.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting announcement:", error);
    return null;
  }
};
export const updateAnnouncement = async (id, masterId, announcementData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_announcement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        ...announcementData,
        "id": parseInt(id),
        "masterId": parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update announcement.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating announcement:", error);
    return null;
  }
};




//view working days
export const viewWorkingDays = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_workingdays`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

//add woriking daya
export const adWorkingDays = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_workingdays`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log('Response Status:', response.status);
    const responseBody = await response.json();
    console.log('Response Body:', responseBody);
    return responseBody;
  } catch (error) {
    throw Error('An error occurred while processing the request.');
  }
};

//delete working day
export const deleteWorkingDays = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_workingdays`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }), // Include both id and masterId in the body
    });

    if (!response.ok) {
      throw new Error('Failed to delete leave.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error deleting leave:', error);
    return null;
  }
};
//edit workingday
export const editWorkingDays = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_workingdays`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'id': parseInt(id) }),
    });

    console.log('id get', id);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error or log it
    // throw new Error('An error occurred while processing the request.');
  }
};

//update workingday
export const updateWorkingDays = async (id, masterId, leaveData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_workingdays`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        ...leaveData,
        'id': parseInt(id),
        'masterId': parseInt(masterId)
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update leave.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating leave:', error);
    return null;
  }
};


//update manual absent days
export const addManualAttendanceAdmin = async (employee_id, status, attendance_date) => {
  try {
    const response = await fetch(`${BASE_URL}/add_manual_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
        'status': parseInt(status),
        'attendance_date': attendance_date
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update Attendance.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating Attendance:', error);
    return null;
  }
};

export const viewEmployeeProfile = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
        "employeeId": getId(),

      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};


// export const editCompanyprofile = async (id) => {
//   try {
//     const response = await fetch(`${BASE_URL}/edit_companyadmin`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // "Authorization": `Bearer ${token}`,
//         "Authorization": getToken(), // Assuming getToken() provides the necessary token
//         "companyId": getcompanyId(),
//       },
//       body: JSON.stringify({ "id": parseInt(companyID) }), // Include the 'id' parameter
//     });

//     console.log("id sent", id);

//     if (!response.ok) {
//       throw new Error("Failed to fetch data.");
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     // Handle error appropriately, throw an error or log it
//     console.error("Error:", error);
//     throw error; // Re-throwing the error to be handled by the caller
//   }
// };

// export const editCompanyprofile = async () => {
//   try {
//     const companyId = getcompanyId(); // Retrieve companyID from session storage
//     if (!companyId) {
//       throw new Error('Company ID not found in session storage.');
//     }

//     const response = await fetch(`${BASE_URL}/edit_companyadmin`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         // "Authorization": `Bearer ${token}`,
//         'Authorization': getToken(), // Assuming getToken() provides the necessary token
//       },
//       body: JSON.stringify({ 'id': parseInt(companyId) }), // Pass companyID as 'id'
//     });

//     console.log('id sent', companyId);

//     if (!response.ok) {
//       throw new Error('Failed to fetch data.');
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     // Handle error appropriately, throw an error or log it
//     console.error('Error:', error);
//     throw error; // Re-throwing the error to be handled by the caller
//   }
// };

// export const editCompanyprofile = async (companyId) => {
//   try {
//     if (!companyId) {
//       throw new Error('Company ID not provided.');
//     }

//     const response = await fetch(`${BASE_URL}/edit_companyadmin?id=${companyId}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(), // Assuming getToken() provides the necessary token
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch company profile data.');
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     console.error('Error:', error);
//     throw error; // Re-throwing the error to be handled by the caller
//   }
// };


// export const editCompanyprofile = async ({ id:companyID}) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_attendance`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//         "companyId": getcompanyId(),
//       },
//       body: JSON.stringify({
//         "companyID": parseInt({id:companyID}),
//       }),
//     });

//     if (!response.ok) {
//       throw new Error("Failed to add employee attendance.");
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error("Error adding employee attendance:", error);
//     return null;
//   }
// };


// export const editCompanyprofile = async ({ id }) => {
//   try {
//     const companyId = getcompanyId(); // Retrieve companyID from session storage
//     if (!companyId) {
//       throw new Error('Company ID not found in session storage.');
//     }

//     const response = await fetch(`${BASE_URL}/edit_companyadmin`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(), // Assuming getToken() provides the necessary token
//       },
//       body: JSON.stringify({ 'id': parseInt(id), 'companyID': parseInt(companyId) }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to edit company profile.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error editing company profile:', error);
//     return null;
//   }
// };


















export const getcompanyId = () => {
  const companyId = sessionStorage.getItem('companyId')
  console.log(companyId);
  return companyId ? companyId : '';

};

const companyid = getcompanyId();
console.log(companyid);


// export const getuserEmail = () => {
//   const userEmail = sessionStorage.getItem('userEmail')
//   console.log(userEmail);
//   return userEmail ? userEmail : '';

// };

// const useremail = getuserEmail();
// console.log(useremail);




// export const getId = () => {
//   const id = sessionStorage.getItem('id')
//   return id ? id : '';
// }
// const emp = getId()
// console.log(emp)



// export const editCompanyprofile = async (id) => {
//   try {
//     const companyId = getcompanyId(); 
//     const response = await fetch(`${BASE_URL}/edit_companyadmin`, {

//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(), // Make sure to define and provide the getToken function
//         "getcompanyprofileId": companyId // Use the obtained companyID here
//       },
//       body: JSON.stringify({ "id": parseInt(companyId) }),

//     });


//     console.log("id get", companyId);

//     if (!response.ok) {
//       throw new Error("Failed to fetch data.");
//     }

//     const responseData = await response.json();
//     console.log(responseData);
//     return responseData;


//   } catch (error) {
//     // Handle error appropriately, throw an error, or log it
//     // throw new Error("An error occurred while processing the request.");
//   }

// };



export const editCompanyprofile = async (id) => {

  try {
    const companyId = getcompanyId();
    const response = await fetch(`${BASE_URL}/edit_companyadmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
        "getcompanyprofileId": getcompanyId()
      },
      body: JSON.stringify({ "id": parseInt(companyId) }),
    });

    console.log("companyId get", companyId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error, or log it
    // throw new Error("An error occurred while processing the request.");
  }
};



export const updateCompanyprofile = async (id, companyprofileData) => {
  try {
    const companyId = getcompanyId();
    const response = await fetch(`${BASE_URL}/update_companyadmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
        "getcompanyprofileId": getcompanyId()
      },
      body: JSON.stringify({ ...companyprofileData, "id": parseInt(companyId) }),
    });

    console.log("companyId get", companyId);

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;

  } catch (error) {
    // Handle error appropriately, throw an error, or log it
    // throw new Error("An error occurred while processing the request.");
  }
};






export const changePassword = async (id, currentPassword, newPassword, confirmPassword) => {
  try {
    if (newPassword !== confirmPassword) {
      throw new Error('New password and confirm password do not match.');
    }

    const response = await fetch(`${BASE_URL}/changepassword_employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
      body: JSON.stringify({
        id: parseInt(id),
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to change password.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error changing password:', error.message);
    return null;
  }
};



// export const getemployeeId = () => {
//   const id = sessionStorage.getItem('id')
//   return id ? id : '';

// };

// const employeeid = getemployeeId();
// console.log(employeeid);


// export const addEmployeeBreak = async ({ employee_id, break_status }) => {
//   try {
//     const employeeId = getemployeeId(); 
//     console.log(break_status);
//     const response = await fetch(`${BASE_URL}/add_employee_break`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//         // 'employeeId': getId(),
//         "getemployeeId": getemployeeId() 

//       },
//       body: JSON.stringify({
//         'employee_id': parseInt(employeeId),
//         'break_status': break_status
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add employee break.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error adding employee break:', error);
//     return null;
//   }
// };

export const getemployeeId = () => {
  const id = sessionStorage.getItem('id');
  return id ? id : '';
};

const employeeid = getemployeeId();
console.log('Stored employeeId:', employeeid);

// export const addEmployeeBreak = async ({id, break_status }) => {
//   try {
//     const employeeId = getemployeeId(); 
//     console.log('Retrieved employeeId:', employeeId);
//     console.log('Break Status:', break_status);

//     const response = await fetch(`${BASE_URL}/add_employee_break`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         'employee_id': parseInt(employeeId),
//         'break_status': break_status
//       }),
//     });

//     // ... rest of your code remains the same
//   } catch (error) {
//     console.error('Error adding employee break:', error);
//     return null;
//   }
// };



// export const addEmployeeBreak = async ({ employee_id, break_status }) => {
//   try {
//     const employeeId = getemployeeId(); 
//     console.log('Retrieved employeeId:', employeeId);
//     console.log('Break Status:', break_status);

//     const response = await fetch(`${BASE_URL}/add_employee_break`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         'employee_id': parseInt(employeeId),
//         'break_status': break_status
//       }),
//     });

//     const responseData = await response.json();
//     console.log(responseData);
//     return responseData;
//   } catch (error) {
//     console.error('Error adding employee break:', error);
//     // return null;

//   }
// };


// export const addEmployeeBreak = async ({ employee_id, break_status }) => {
//   try {
//     const employeeId = getemployeeId(); 
//     console.log('Retrieved employeeId:', employeeId);
//     console.log('Break Status:', break_status);

//     const response = await fetch(`${BASE_URL}/add_employee_break`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         'employee_id': parseInt(employeeId),
//         'break_status': break_status
//       }),
//     });

//     const responseData = await response.json(); // Parse the JSON response
//     console.log(responseData);
//     return responseData; // Return the parsed JSON response
//   } catch (error) {
//     console.error('Error adding employee break:', error);
//     // Return an appropriate value in case of an error
//     return { error: 'Error occurred while adding employee break' };
//   }
// };





export const addEmployeeBreak = async ({ employee_id, break_status }) => {

  try {
    const employeeId = getemployeeId();
    const token = getToken()
    console.log('Retrieved employeeId:', employeeId);
    console.log('Break Status:', break_status);
    console.log('Token:', token);

    const response = await fetch(`${BASE_URL}/add_employee_break`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        "getcompanyemployeeId": getemployeeId(),


      },
      body: JSON.stringify({
        'employee_id': parseInt(employeeId),
        'break_status': break_status
      }),
    });
    console.log(response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Response error text:', errorText);
      throw new Error(`Server responded with status ${response.status}: ${errorText}`);
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error('Error in addEmployeeBreak:', error.message);
    return { error: error.message };
  }
};


export const addEmployeeAttendance = async ({ employee_id, attendance_status }) => {

  try {
    const employeeId = getemployeeId();
    console.log('Retrieved employeeId:', employeeId);
    console.log('Attendance Status:', attendance_status);

    const response = await fetch(`${BASE_URL}/add_employee_attendance`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        "getcompanyemployeeId": getemployeeId(),


      },
      body: JSON.stringify({
        'employee_id': parseInt(employeeId),
        'attendance_status': attendance_status
      }),
    });
    // if (!response.ok) {
    //   throw new Error('Failed to change password.');
    // }
    const responseData = await response.text();
    console.log(responseData);
    return responseData;
  } catch (error) {

  }
};

export const updateEmployeeLatestAttendanceAdmin = async (employee_id, attendance_time, status, attendanceId) => {
  try {
    const bearerToken = sessionStorage.getItem("bearerToken");

    const response = await fetch(`${BASE_URL}/update_employee_latest_attendance_admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: Bearer ${bearerToken}, 
        Authorization: getToken(), // Correctly append the bearer token
      },
      body: JSON.stringify({
        employee_id: employee_id,
        attendance_time: attendance_time,
        status: status,
        attendanceId: attendanceId,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update employee latest attendance");
    }

    const data = await response.json();
    console.log("Update Employee Latest Attendance Response:", data);

    return {
      success: data.success == true,
      message: data.message || "Unknown message",
    };
  } catch (error) {
    console.error("Error updating employee latest attendance:", error);
    throw error;
  }
}


// export const addEmployeeAttendance = async ({ employee_id, attendance_status }) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_attendance`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//         "employeeId": getId(),
//       },
//       body: JSON.stringify({
//         "employee_id": parseInt(employee_id),
//         "attendance_status": attendance_status
//       }),
//     });

//     if (!response.ok) {
//       throw new Error("Failed to add employee attendance.");
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error("Error adding employee attendance:", error);
//     return null;
//   }
// };




































export const viewEmployeeAttendance = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_attendance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee attendance data.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error('An error occurred while fetching employee attendance data.');
  }
};


//view attendance
export const viewAttendance = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_attendance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData.data; // Returning only the data part
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};





// export const addLogo = async (adminId, logo) => {
//   try {
//     const companyId = getcompanyId(); 
//     const response = await fetch(`${BASE_URL}/add_logo`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//         "getcompanyprofileId": getcompanyId()
//       },
//       body: JSON.stringify({
//         "admin_id": parseInt(companyId),
//         "logo": logo,
//       }),
//     });

//     console.log("companyId get", companyId);

//     if (!response.ok) {
//       throw new Error("Failed to add logo.");
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error("Error adding logo:", error);
//     return null;
//   }
// };

export const addLogo = async (logo) => {
  try {
    const companyId = getcompanyId();
    const formData = new FormData();
    formData.append('admin_id', parseInt(companyId));
    formData.append('logo', logo.get('logo'));

    const response = await fetch(`${BASE_URL}/update_logo`, {
      method: "POST",
      headers: {
        "Authorization": getToken(),
        // "getcompanyprofileId": getcompanyId(),
      },
      body: formData,
    });

    // console.log("companyId get", companyId);

    if (!response.ok) {
      throw new Error("Failed to add logo.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error adding logo:", error);
    return null;
  }
};

export const updateEmployeeImage = async (image, employeeId) => {
  try {
    const formData = new FormData();
    formData.append('employee_id', employeeId);
    formData.append('image', image);

    const response = await fetch(`${BASE_URL}/update_employee_image`, {
      method: "POST",
      headers: {
        "Authorization": getToken(),
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to update employee image.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating employee image:", error);
    return null;
  }
};



// export const viewLogo = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/view_logo`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // Include any other headers if needed
//         'Authorization': getToken(),
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch data.');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };


// single view employee attendance 



export const viewLogo = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_logo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        // Include any other headers if needed
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    // Assuming the response structure is { success: true, data: 'logo_url_here' }
    if (responseData.success) {
      // You can access the logo URL using responseData.data
      console.log('Logo URL:', responseData.data);
      return responseData.data;
    } else {
      throw new Error('API response indicates failure.');
    }
  } catch (error) {
    console.error('An error occurred while processing the request:', error);
    throw new Error('An error occurred while processing the request.');
  }
};




export const singleViewEmployeeAttendance = async (employee_id, year, month) => {
  let employeeId = parseInt(employee_id)
  try {
    const response = await fetch(`${BASE_URL}/single_view_employee_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        'employeeId': getId(),
      },
      body: JSON.stringify({ employee_id: employeeId, year, month }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to fetch employee attendance');
    }

    const data = await response.json();
    console.log('Employee Attendance Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee attendance count:', error);
    throw error;
  }
};

export const checkemployeeattandance = async (employee_id) => {
  try {
    const response = await fetch(`${BASE_URL}/check_employee_attandance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        'employeeId': getId(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
      }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to fetch employee attendance');
    }

    const data = await response.json();
    console.log('Employee Attendance Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee last attendance', error);
    throw error;
  }
};




export const viewEmployeeBreakCount = async ({ employee_id }) => {
  try {
    const response = await fetch(`${BASE_URL}/single_view_employee_break`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        'employeeId': getId(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
      }),
    });

    console.log(response);


    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee attendance count:', error);
    throw error;
  }
};


// checking 
export const viewEmployeeBreakCount1 = async ({ employee_id }) => {
  try {
    const response = await fetch(`${BASE_URL}/check_break_employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        'employeeId': getId(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
      }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.text();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee attendance count:', error);
    throw error;
  }
};



export const searchEmployeeBreakCount = async (employee_id, year, month) => {
  try {
    const response = await fetch(`${BASE_URL}/single_search_employee_break`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
        'year': year,
        'month': month,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const searchEmployeeBreak = async (year, month) => {
  try {
    const response = await fetch(`${BASE_URL}/search_employee_break`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        // 'employee_id': parseInt(employee_id),
        'year': year,
        'month': month,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const dateviewemployeebreaklist = async (date) => {
  try {
    const response = await fetch(`${BASE_URL}/date_view_employee_break_list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ date: date }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Time:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};


export const searchEmployeeAttendance = async (year, month) => {
  try {
    const response = await fetch(`${BASE_URL}/search_employee_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        // 'employee_id': parseInt(employee_id),
        'year': year,
        'month': month,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const editbreakdetails = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_break_details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Time:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const updatebreakdetails = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/update_break_details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Time:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const deletebreak = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_break`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Time:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const deleteemployeeattendance = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_employee_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Time:', data);

    return data;

  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const getemployeedatewiseattendance = async (currentDateTime) => {
  try {
    const response = await fetch(`${BASE_URL}/get_employee_datewise_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ date: currentDateTime }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch employee break count');
    }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};


export const viewEmpAttendance = async (date) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        date: date
      }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch employee break count');
    // }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const viewemployeepaginationattendanceadmin = async (date, page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_pagination_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        date: date,
        page: page,
        pageSize: pageSize
      }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch employee break count');
    // }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const searchviewemployee = async (search) => {
  try {
    const response = await fetch(`${BASE_URL}/search_view_employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ search }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch employee break count');
    // }

    const data = await response.json();
    console.log('Employee Break Count Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee break count:', error);
    throw error;
  }
};

export const editEmployeeAttendanceAdmin = async (masterId, id) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_employee_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'masterId': masterId, 'id': id }),
    });

    if (!response.ok) {
      throw new Error('Failed to edit employee attendance.');
    }

    const responseData = await response.json();
    if (responseData.success) {
      return responseData.success;
    } else {
      throw new Error('No success data found.');
    }

  } catch (error) {
    console.error('Error editing employee attendance:', error);
    throw new Error('Failed to edit employee attendance.');
  }
};

//   export const updateEmployeeAttendanceAdmin = async (masterId, id, employee_id, attendance_time, attendance_status) => {
//     try {
//         const response = await fetch(`${BASE_URL}/update_employee_attendance_admin`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': getToken(),
//             },
//             body: JSON.stringify({
//                 'masterId': parseInt(masterId),
//                 'id': parseInt(id),
//                 'employee_id': parseInt(employee_id),
//                 'attendance_time': attendance_time,
//                 'attendance_status': attendance_status
//             }),
//         });

//         if (!response.ok) {
//             throw new Error('Failed to update employee attendance.');
//         }

//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error updating employee attendance:', error);
//         return null;
//     }
// };

export const updateEmployeeAttendanceAdmin = async (masterId, id, employee_id, attendance_time, attendance_status) => {
  try {
    const response = await fetch(`https://my.windsonpayroll.com/API/public/api/update_employee_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        'masterId': parseInt(masterId),
        'id': parseInt(id),
        'employee_id': parseInt(employee_id),
        'attendance_time': attendance_time,
        'attendance_status': attendance_status

      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update employee attendance.');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error updating employee attendance:', error);
    return null;
  }
};

export const deleteEmployeeAttendanceAdmin = async (masterId, id) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_employee_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'masterId': masterId, 'id': id }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete employee attendance.');
    }

    const responseData = await response.json();
    if (responseData.success) {
      return responseData.success;
    } else {
      throw new Error('No success data found.');
    }

  } catch (error) {
    console.error('Error deleting employee attendance:', error);
    throw new Error('Failed to delete employee attendance.');
  }
};

export const editemployeeattendance = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_employee_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to delete employee attendance.');
    }

    const responseData = await response.json();
    if (responseData.success) {
      return responseData.success;
    } else {
      throw new Error('No success data found.');
    }

  } catch (error) {
    console.error('Error deleting employee attendance:', error);
    throw new Error('Failed to delete employee attendance.');
  }
};

export const updateemployeeattendance = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/update_employee_attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to delete employee attendance.');
    }

    const responseData = await response.json();
    if (responseData.success) {
      return responseData.success;
    } else {
      throw new Error('No success data found.');
    }

  } catch (error) {
    console.error('Error deleting employee attendance:', error);
    throw new Error('Failed to delete employee attendance.');
  }
};





//   export const addEmployeeAttendanceAdmin = async (employeeIds, attendanceStatus, attendanceTime) => {
//     try {
//         const response = await fetch(`${BASE_URL}/add_employee_attendance_admin`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': getToken(),
//             },
//             body: JSON.stringify({
//                 'employee_id': employeeIds,
//                 'attendance_status': attendanceStatus,
//                 'attendance_time': attendanceTime,
//             }),

//         });

//         if (!response.ok) {
//             throw new Error('Failed to add employee attendance');
//         }

//         const data = await response.json();
//         console.log('Add Employee Attendance Data:', data);
//         return data;
//     } catch (error) {
//         console.error('Error adding employee attendance:', error);
//         throw error;
//     }
// };


// export const addEmployeeAttendanceAdmin = async (employee_id, attendance_status, attendance_time) => {
//   try {
//       const response = await fetch(`${BASE_URL}/add_employee_attendance_admin`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//               'Authorization': getToken(),
//           },
//           body: JSON.stringify({
//               'employee_id': employeeId,
//               'attendance_status': attendanceStatus,
//               'attendance_time': attendanceTime,
//           }),
//       });

//       if (!response.ok) {
//           throw new Error('Failed to add employee attendance');
//       }

//       const data = await response.json();
//       console.log('Add Employee Attendance Data:', data);
//       return data;
//   } catch (error) {
//       console.error('Error adding employee attendance:', error);
//       throw error;
//   }
// };


export const addEmployeeAttendanceAdmin = async (employee_id, attendance_status, attendance_time) => {
  try {
    const response = await fetch(`${BASE_URL}/add_employee_attendance_admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        'employee_id': employee_id,
        'attendance_status': attendance_status,
        'attendance_time': attendance_time,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to add employee attendance');
    }

    const data = await response.json();
    console.log('Add Employee Attendance Data:', data);
    return data;
  } catch (error) {
    console.error('Error adding employee attendance:', error);
    throw error;
  }
};


export const addEmployeeSalary = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_employee_salary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`,
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });

    console.log("Response Status:", response.status);

    const responseBody = await response.text();

    console.log("Response Body:", responseBody);

    return responseBody;
  } catch (error) {
    throw Error("An error occurred while processing the request.");
  }
};
export const viewSalarySetup = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_salary_setup`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Return responseData instead of responseData.data
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};
export const empSalarySetup = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_salary_setup`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Return responseData instead of responseData.data
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const viewsalarystructuresetup = async ({ year, month }) => {
  try {
    const response = await fetch(`${BASE_URL}/view_salary_structure_setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ year, month }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Return responseData instead of responseData.data
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const viewsalarystructuresetuppaggination = async ({ year, month, page, pageSize }) => {
  try {
    const response = await fetch(`${BASE_URL}/view_salary_structure_setup_paggination`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ year, month, page, pageSize }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }


    const responseData = await response.json();
    console.log(responseData);

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData; // Return responseData instead of responseData.data
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const getsingleemployeesetup = async ({ year, month, employeeId }) => {
  try {
    const response = await fetch(`${BASE_URL}/get_single_employee_setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ year, month, employeeId }),
    });

    const responseData = await response.json();
    console.log(responseData);

    return responseData;
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};











export const viewUpcomingbirthdays = async () => {
  try {
    const response = await fetch(`${BASE_URL}/AdminDashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success) {
      throw new Error('Invalid response format.');
    }

    return responseData.success; // Returning the "success" array directly
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

//--------------upcoming birthday-----------------------------
export const dashboardCommonapi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/AdminDashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred while processing the request.");
  }
};

















// export const addSalarySetup = async (employeeId, basic, deduction, allowance) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_salary_setup`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         employee_id: employeeId,
//         basic: basic,
//         deduction: deduction,
//         allowance: allowance,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add salary setup.');
//     }

//     const responseData = await response.json();

//     if (!responseData.success || !responseData.data) {
//       throw new Error('Invalid response format.');
//     }

//     return responseData.data; 
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };




// export const addSalarySetup = async (name, basic, deductions, allowances) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_salary_setup`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         name: name,
//         basic: basic,
//         deductions: deductions,
//         allowances: allowances
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add salary setup.');
//     }

//     const responseData = await response.json();

//     if (!responseData.success || !responseData.data) {
//       throw new Error('Invalid response format.');
//     }

//     return responseData.data;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };





// export const updateEmployeePassword = async (empId, password) => {
//   try {
//     const response = await fetch("${BASE_URL}/employee_update_password", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//       body: JSON.stringify({
//         employee_id: empId,
//         password: password,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error("Failed to update password.");
//     }

//     const responseData = await response.json();
//     return responseData; // Return the response data
//   } catch (error) {

//     // console.error("Error updating password:", error);
//     // return null; 
//   }
// };

// export const addSalarySetup = async (payload) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_salary_setup`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify(payload),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add salary setup.');
//     }

//     const responseData = await response.json();

//     if (!responseData.success || !responseData.data) {
//       throw new Error('Invalid response format.');
//     }

//     return responseData.data;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };


export const addManualSalarySetup = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_mannual_salary_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw new Error('Failed to add manual salary setup.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const editsalarystructuresetup = async ({ year, month, name }) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_salary_structure_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify({ year, month, name })
    });

    if (!response.ok) {
      throw new Error('Failed to add manual salary setup.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const getSingleEmployeeDetails = async ({ id }) => {
  try {
    const response = await fetch(`${BASE_URL}/get_single_employee`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify({ id })
    });

    if (!response.ok) {
      throw new Error('Failed to get employee data.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const getMonthlyAllSalaryEmployee = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_monthly_all_salary_employee`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify()
    });

    if (!response.ok) {
      throw new Error('Failed to get all employee data.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};


export const update_salary_structure_setup = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_salary_structure_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw new Error('Failed to add manual salary setup.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const edit_salary_struct = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_salary_struct`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw new Error('Failed to add manual salary setup.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const addSalarySetup = async (formData) => {

  try {
    const response = await fetch(`${BASE_URL}/add_salary_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw new Error('Failed to add salary setup.');
    }

    const responseData = await response.text();

    return responseData;
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};
// export const addempSalarySetup = async (employee_id,formData) => {

//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_salary_setup`, {
//       method: 'POST',
//       headers: {
//         "Content-Type": "application/json",
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({employee_id: employee_id,formData})
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add salary setup.');
//     }

//     const responseData = await response.text();

//     return responseData;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };


// export const addempSalarySetup = async (employee_id,structure_id, formData) => {
//   try {
//     const response = await fetch(`${BASE_URL}/add_employee_salary_setup`, {
//       method: 'POST',
//       headers: {
//         "Content-Type": "application/json",
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({ ...formData, employee_id: employee_id,structure_id: structure_id })
//     });

//     if (!response.ok) {
//       throw new Error('Failed to add salary setup.');
//     }

//     const responseData = await response.json();

//     return responseData;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };
export const addempSalarySetup = async (employee_id, salary_structure_id, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/add_employee_salary_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify({ ...formData, employee_id: employee_id, salary_structure_id: salary_structure_id })
    });

    if (!response.ok) {
      throw new Error('Failed to add salary setup.');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};
export const searchEmployeeSalarySetup = async (employee_id, struct_id) => {
  try {
    const response = await fetch(`${BASE_URL}/single_employee_salary_setup`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': getToken(),
      },
      body: JSON.stringify({ employee_id: employee_id, struct_id: struct_id })
    });

    if (!response.ok) {
      // throw new Error('Failed to search for salary setup.');
    }


    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // throw new Error('An error occurred while processing the request.');
  }
};

export const viewEmployeeSalarySetup = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_salary_setup`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    // throw new Error("An error occurred while processing the request.");
  }
};










export const updateEmployeePassword = async (empId, password) => {
  try {

    const response = await fetch(`${BASE_URL}/employee_update_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        employee_id: empId,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update password.");
    }

    const responseData = await response.json();
    return responseData; // Return the response data
  } catch (error) {
    console.error("Error updating password:", error);
    return null;
  }
};

export const updateUserPassword = async (userId, password) => {
  try {
    const response = await fetch(`${BASE_URL}/user_update_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(), // Assuming getToken() function retrieves the token
      },
      body: JSON.stringify({
        user_id: userId,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update user password.");
    }

    const responseData = await response.json();
    return responseData; // Return the response data
  } catch (error) {
    console.error("Error updating user password:", error);
    return null;
  }
};


// export const viewCreditLeave = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/credit_leave`, {
//       method: 'GET',
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": getToken(),
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch data.');
//     }

//     const responseData = await response.json();

//     if (!responseData.success || !responseData.data) {
//       throw new Error('Invalid response format.');
//     }

//     return responseData.data;
//   } catch (error) {
//     throw new Error('An error occurred while processing the request.');
//   }
// };



export const viewCreditLeave = async () => {
  try {
    const response = await fetch(`${BASE_URL}/credit_leave`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    // Parse the response data into an array of objects
    const parsedData = responseData.data.map(item => {
      const [employeeName, totalLeave] = item.split(', ');
      return {
        employee_name: employeeName.split(': ')[1],
        total_leave: parseInt(totalLeave.split(': ')[1])
      };
    });

    return parsedData;
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};

export const creditleavepagination = async (page, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/credit_leave_pagination`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({
        page: page,
        pageSize: pageSize,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();

    if (!responseData.success || !responseData.data) {
      throw new Error('Invalid response format.');
    }

    return responseData;  // No need to parse the data manually
  } catch (error) {
    throw new Error('An error occurred while processing the request.');
  }
};




export const editSalarySetup = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_salary_setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({ 'id': parseInt(id), 'masterId': parseInt(masterId) }),
    });

    console.log('id get', id);
    console.log('masterid get', masterId);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {

  }
};

// export const updateSalarySetup = async (id, masterId, name, basic, deduction, allowance) => {
//   try {
//     const response = await fetch(`${BASE_URL}/update_salary_setup`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': getToken(),
//       },
//       body: JSON.stringify({
//         id: parseInt(id),
//         masterId: parseInt(masterId),
//         name: name,
//         basic: basic,
//         deduction: deduction,
//         allowance: allowance
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update salary setup.');
//     }

//     const responseData = await response.json();
//     return responseData;

//   } catch (error) {
//     console.error('Error updating salary setup:', error);
//     throw error;
//   }
// };



export const updateSalarySetup = async (id, masterId, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/update_salary_setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
      body: JSON.stringify({
        id: id,
        masterId: masterId,
        ...formData,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update salary setup.');
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    console.error('Error updating salary setup:', error);
    throw error;
  }
};

export const deleteSalarysetup = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_salary_setup 	`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete employee.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting employee:", error);
    return null;
  }
};



export const deleteBreakoffemp = async (id, masterId) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_breakoff_emp 	`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id: parseInt(id), masterId: parseInt(masterId) }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete employee.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting employee:", error);
    return null;
  }
};
export const viewBreakOffemplist = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_breakoff_emp`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    // throw new Error("An error occurred while processing the request.");
  }
};


export const addBreakoffemp = async (employeeId, date) => {
  try {
    const response = await fetch(`${BASE_URL}/add_breakoff_emp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ employee_id: employeeId, date: date }),
    });

    if (!response.ok) {
      throw new Error("Failed to add break off for employee.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error adding break off for employee:", error);
    return null;
  }
};


export const getSingleSalarySetup = async (structure_id) => {
  try {
    const response = await fetch(`${BASE_URL}/singleget_salary_setup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ structure_id: parseInt(structure_id) }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch salary structure.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error fetching salary structure:", error);
    return null;
  }
};

// export const addCandidateInfo = async (data) => {
//   try {
//       const response = await fetch(`${BASE_URL}/add_employee_application_info`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//       });
//       const responseData = await response.json();
//       return responseData;
//   } catch (error) {
//       console.error('Error adding candidate info:', error);
//       throw error;
//   }
// };


// export const addCandidateInfo = async (data) => {
//   try {
//       const response = await fetch(`${BASE_URL}/add_employee_application_info`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//       });

//       if (!response.ok) {
//           throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       return responseData;
//   } catch (error) {
//       console.error('Error adding candidate info:', error);
//       throw error;
//   }
// };



// export const addCandidateInfo = async (data) => {
//   try {
//       const response = await fetch(`${BASE_URL}/add_employee_application_info`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//       });

//       if (!response.ok) {
//           throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       return responseData;
//   } catch (error) {
//       console.error('Error adding candidate info:', error);
//       throw error;
//   }
// };













export const addCandidateInfo = async (dataToSend) => {
  try {
    // const bearerToken = sessionStorage.getItem('bearerToken');

    const response = await fetch(`${BASE_URL}/add_employee_application_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": getToken(),
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error adding candidate info:', error);
    throw error;
  }
};

// export const downloadPdfFile = async (resumeNo) => {
//   try {
//     const response = await fetch(`${BASE_URL}/download_PdfFile`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ resume: resumeNo }),
//     });

//     console.log(response);


//     // const responseData = await response.json();
//     // return responseData;
//   } catch (error) {
//     console.error('Error adding candidate info:', error);
//     throw error;
//   }
// };

export const downloadPdfFile = async (resumeNo) => {
  try {
    const response = await fetch(`${BASE_URL}/download_PdfFile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ resume: resumeNo }), // Ensure resumeNo is a simple value
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.blob();
  } catch (error) {
    console.error('Error downloading the file:', error);
    throw error;
  }
};


















// export const addCandidateInvite = async ({ name, emailid, token, companyId }) => {
//   try {
//       const response = await fetch(`${BASE_URL}/candidate_invite`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${token}`  // Assuming token needs to be sent in the Authorization header
//           },
//           body: JSON.stringify({ name, emailid, userId: companyId }), // Using companyId instead of userId
//       });

//       if (!response.ok) {
//           throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       return responseData;
//   } catch (error) {
//       console.error('Error adding candidate invite:', error);
//       throw error;
//   }
// };



// export const addCandidateInvite = async ({ name, emailid, token, companyId }) => {
//   try {
//     const response = await fetch(`${BASE_URL}/candidate_invite`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify({ name, emailid, userId: companyId }),
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error adding candidate invite:', error);
//     throw error;
//   }
// };


export const addCandidateInvite = async ({ email, token }) => {
  try {
    const response = await fetch(`${BASE_URL}/invite_candidate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error adding candidate invite:', error);
    throw error;
  }
};

export const addJobDetails = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/addJobDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": getToken(),
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error adding candidate invite:', error);
    throw error;
  }
};

export const getSingleCompanyJobs = async () => {
  try {
    const response = await fetch(`${BASE_URL}/getSingleCompanyJobs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error adding fetching jobs:', error);
    throw error;
  }
};

export const viewemployeetoken = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": getToken(),
      },
      // body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error adding candidate invite:', error);
    throw error;
  }
};


export const viewEmployeeDetails = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_employee_detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to view employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error viewing employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};



export const viewSingleEmployeeDetail = async (id, subId) => {
  try {
    const response = await fetch(`${BASE_URL}/view_single_employee_detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id, subId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};

export const shortListedemployee = async (id, subId) => {
  try {
    const response = await fetch(`${BASE_URL}/shortListed_employee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id, subId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};
export const directShortListedEmployee = async (id, subId, email) => {
  try {
    const response = await fetch(`${BASE_URL}/directShortListedEmployee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id, subId, email }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};

export const statuschangesapplicate = async (id, subId, subStatus) => {
  try {
    const response = await fetch(`${BASE_URL}/status_changes_applicate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      body: JSON.stringify({ id, subId, subStatus }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};

export const viewshortlistedemployeeshortlisteddetail = async () => {
  try {
    const response = await fetch(`${BASE_URL}/view_shortlisted_employee_shortlisted_detail`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};

export const confirmempolyee = async () => {
  try {
    const response = await fetch(`${BASE_URL}/confirm_empolyee`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch employee details.");
    }

    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error("No data found.");
    }

  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw new Error("An error occurred while processing the request.");
  }
};



export const fetchEmployeeSalaryCalculation = async (year, month) => {
  try {
    const response = await fetch(`${BASE_URL}/emp_salary_calculation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(), // Ensure you have a function to retrieve the token
      },
      body: JSON.stringify({
        'year': year,
        'month': month,
      }),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch employee salary calculation');
    // }

    const data = await response.json();
    console.log('Employee Salary Calculation Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching employee salary calculation:', error);
    throw error; // Rethrow the error so the caller can handle it
  }
};



// export const matchToken = async (token) => {
//   try {
//     const response = await fetch(`${BASE_URL}/check_token`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         'token': token,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to check token validity');
//     }

//     const data = await response.json();
//     console.log('Token Check Response:', data);
//     return data.success === 'true'; 
//   } catch (error) {
//     console.error('Error checking token validity:', error);
//     throw error;
//   }
// };


// export const matchToken = async (tokenNew) => {
//   try {
//     const bearerToken = sessionStorage.getItem('bearerToken');
//     const response = await fetch(`${BASE_URL}/check_token`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${bearerToken}`,
//       },
//       body: JSON.stringify({
//         tokenNew: tokenNew,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to check token validity');
//     }

//     const data = await response.json();
//     console.log('Token Check Response:', data);
//     return data.success === 'true'; 
//   } catch (error) {
//     console.error('Error checking token validity:', error);
//     throw error;
//   }
// };

export const matchToken = async (tokenNew) => {
  try {
    const bearerToken = sessionStorage.getItem('bearerToken');
    const response = await fetch(`${BASE_URL}/check_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        tokenNew: tokenNew,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to check token validity');
    }

    const data = await response.json();
    console.log('Token Check Response:', data);

    return {
      success: data.success === 'true',
      message: data.message || 'Unknown message',
    };
  } catch (error) {
    console.error('Error checking token validity:', error);
    throw error;
  }
};


export const viewtokenlogo = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/view_token_logo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id }),
    });

    if (!response.ok) {
      throw new Error('Failed to check token validity');
    }

    const data = await response.json();
    console.log('Token Check Response:', data);

    return data;
  } catch (error) {
    console.error('Error checking token validity:', error);
    throw error;
  }
};

export const viewsingleemployeetokendetail = async (token, bearerToken) => {
  try {
    const response = await fetch(`${BASE_URL}/view_single_employee_token_detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, bearerToken: bearerToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to check token validity');
    }

    const data = await response.json();
    console.log('Token Check Response:', data);

    return data;
  } catch (error) {
    console.error('Error checking token validity:', error);
    throw error;
  }
};



export const checkEmployeeBreakStatus = async ({ employee_id }) => {
  try {
    const response = await fetch(`${BASE_URL}/check_break_employee`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
        'employeeId': getId(),
      },
      body: JSON.stringify({
        'employee_id': parseInt(employee_id),
      }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to check employee break status');
    }

    const data = await response.json();
    console.log('Employee Break Status Data:', data);
    return data;
  } catch (error) {
    console.error('Error checking employee break status:', error);
    throw error;
  }
};