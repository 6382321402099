import React, { useState, useEffect, useRef, useDebugValue, useCallback } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-bootstrap-icons';
import { BASE_URL, edit_salary_struct, editsalarystructuresetup, fetchEmployeeSalaryCalculation, getMonthlyAllSalaryEmployee, update_salary_structure_setup, viewsalarystructuresetup, viewsalarystructuresetuppaggination } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { debounce } from '@mui/material';

const Employeesalary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tableRef = useRef(null);
    const [employeeSalaryData, setEmployeeSalaryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showAddSalaryModal, setShowAddSalaryModal] = useState(false);
    const [changable, setIsChangeAble] = useState(false);
    const [salaryForm, setSalaryForm] = useState({ year: '', month: '' });
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().toLocaleString('default', { month: 'long' }).toLowerCase(); // Convert month to lowercase


    // const [salaryForm, setSalaryForm] = useState({
    //     year: localStorage.getItem('selectedYear') || currentYear.toString(),
    //     month: localStorage.getItem('selectedMonth') || currentMonth
    // });

    const [isScrolled, setIsScrolled] = useState(false);
    const gridContainerRef = useRef(null);

    const fromSalaryPDF = location.state?.fromSalaryPDF || false;
    const [rows, setRows] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [rowId, setRowId] = useState(null);
    const [monthlySalary, setMonthlySalaryEmp] = useState(null);
    const [editEmployeeData, setEditEmployeeData] = useState(null);
    const [formData, setFormData] = useState({});
    const [editGross, setEditGross] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [calculatedTotalSalary, setCalculatedTotalSalary] = useState('0.00');
    const [showModal, setShowModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setpageSize] = useState(100);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const pageRef = useRef(page);
    const [error, setError] = useState(null);
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [viewEmployees, setViewEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [perDayBasic, setPerDayBasic] = useState(0);
    const [initialBasicAmount, setInitialBasic] = useState(0);
    const [editCombineGrossData, setEditCombineGrossData] = useState([]);
    const [viewGross, setViewGross] = useState(null);
    const [flag, setFlag] = useState(false);
    const [netTotal, setNetTotal] = useState(null);
    const [updatedFormData, setUpdatedFormData] = useState(formData);
    const [changedFields, setChangedFields] = useState({});
    const [isEditable, setIsEditable] = useState(false);



    // useEffect(() => {
    //     const isAdminUser = sessionStorage.getItem('adminUser');

    //     if (!isAdminUser || isAdminUser == 'false') {
    //         handleShowPasswordModal(true);
    //     } else {
    //         handleShowPasswordModal(false);
    //     }
    // }, []);


    useEffect(() => {
        pageRef.current = page;
    }, [page]);

    const handleSearchChange = (e) => {
        const { value } = e.target
        setSearch(value)
    }

    const customStyles = {
        grid: {
            border: '1px solid #ddd',
            height: '500px'
        },
        cell: {
            border: 'none', // Remove all borders
            borderBottom: '1px solid #ddd', // Only horizontal border
            padding: '0.5rem 0.5rem',
            color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
            backgroundColor: 'white', // Using custom properties for background color
            fontWeight: 'bold', // Making text important
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
            '&:first-child': {
                position: 'sticky',
                left: 0,
                zIndex: 10,
                backgroundColor: 'white',
            },
        },

        columnHeader: {
            border: 'none', // Remove all borders
            borderBottom: '1px solid #ddd', // Only horizontal border
            padding: '0.5rem 0.5rem',
            color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
            backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

            fontWeight: 'bold',
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
        },
        lastColumn: {
            borderRight: 'none',
        },
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getMonthEstimateSalary = async () => {
        try {
            const response = await getMonthlyAllSalaryEmployee();
            if (response && Array.isArray(response) && response.length > 0) {
                console.log("API response:", response);
                setMonthlySalaryEmp(response);
            } else {
                console.error("Invalid API response:", response);
            }
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };

    // Function to calculate total salary
    const calculateTotalSalary = () => {
        if (monthlySalary && monthlySalary.length > 0) {
            const totalBasic = monthlySalary[0]?.total_basic_monthly || 0;
            const totalAllowance = monthlySalary[0]?.total_allowance_monthly || 0;
            const totalDeduction = monthlySalary[0]?.total_deduction_monthly || 0;

            const totalSalary = (totalBasic + totalAllowance - totalDeduction).toFixed(2);
            return totalSalary;
        } else {
            console.error('monthlySalary is null or empty.');
            return '0.00';
        }
    };

    // useEffect to initialize data
    useEffect(() => {
        getMonthEstimateSalary();

        const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
        setYears(generatedYears);

        const monthNames = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        setMonths(monthNames);
    }, []);

    function formatNumberWithCommas(number) {
        return number
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // useEffect to calculate total salary whenever monthlySalary changes
    useEffect(() => {
        const totalSalary = calculateTotalSalary();
        const formattedSalary = formatNumberWithCommas(totalSalary);
        console.log(formattedSalary);

        setCalculatedTotalSalary(formattedSalary);
        console.log("Calculated Total Salary:", totalSalary);
    }, [monthlySalary]);



    const handleShowPasswordModal = () => {
        setShowPasswordModal(true);
        // setFormData({});


    };

    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
    };
    const handleShowModal = () => {
        console.log(formData);
        setShowModal(true);
        setSubmitted(!submitted);
        // setFormData({});


    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({});
    };

    const handleShowViewModal = (params, name) => {
        setShowViewModal(true);

        let selectedEmployee = viewEmployees.find(employee => employee.name == name);

        const combinedEditBasicAndAllowance = [
            ...selectedEmployee.basic,
            ...selectedEmployee.allowence
        ];

        let totalGross = combinedEditBasicAndAllowance
            .filter(allowance => allowance.name !== "Incentive")
            .reduce((sum, item) => sum + item.monthly, 0);

        setViewGross(totalGross)

        if (selectedEmployee) {
            setSelectedEmployee(selectedEmployee)
            console.log("Selected Employee:", selectedEmployee);
            // You can do additional logic with the selectedEmployee here
        } else {
            console.log("No employee found with the name:", name);
        }
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
    };

    useEffect(() => {
        // handleviewsalarystructuresetup()
    }, [submitted]);




    // useEffect(() => {
    //     getMonthEstimateSalary();
    //     const calculateTotalSalary = () => {
    //         if (monthlySalary && monthlySalary.length > 0) {
    //             const totalBasic = monthlySalary[0]?.total_basic_monthly;
    //             const totalAllowance = monthlySalary[0]?.total_allowance_monthly;
    //             const totalDeduction = monthlySalary[0]?.total_deduction_monthly;

    //             const totalSalary = (totalBasic + totalAllowance - totalDeduction).toFixed(2);
    //             return totalSalary;
    //         } else {
    //             console.error('monthlySalary is null or empty.');
    //             return '0.00';
    //         }
    //     };
    //     const totalSalary = calculateTotalSalary();
    //     setCalculatedTotalSalary(totalSalary);
    //     console.log("++++++++++++++++++++++++++++++++++++++++++++++++",totalSalary);
    //     const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
    //     setYears(generatedYears);

    //     const monthNames = [
    //         'january', 'february', 'march', 'april', 'may', 'june',
    //         'july', 'august', 'september', 'october', 'november', 'december'
    //     ];
    //     setMonths(monthNames);

    // }, []);


    // const getMonthEstimateSalary = async () => {
    //     try {
    //         const response = await getMonthlyAllSalaryEmployee();
    //         console.log("manual salary", response);
    //         setMonthlySalaryEmp(response);
    //     } catch (error) {
    //         console.error("Error fetching salary structure setup:", error);
    //     }
    // };

    const getToken = () => {
        const token = sessionStorage.getItem('token');

        return token ? `Bearer ${token}` : '';


    };

    const handleClick = (rowData, params) => {

        let selectedEmployee = viewEmployees.find(employee => employee.name == params.row.name);
        console.log(selectedEmployee);

        console.log(params);

        let year = salaryForm.year
        let month = salaryForm.month
        navigate('/admin/salarypdf', { state: { rowData, year, month, selectedEmployee } });
    };

    const handleExportSalary = async () => {
        setLoading(true); // Show loading state

        if (salaryForm.year && salaryForm.month) {


            try {
                const response = await fetch(`${BASE_URL}/exportSalaryStructureData`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": getToken(),
                    },
                    body: JSON.stringify({ year: salaryForm.year, month: salaryForm.month }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                // Convert JSON data to CSV
                const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

                // Create a Blob and trigger the download
                const blob = new Blob([csvData], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'employee_data.csv'; // Change the file name as needed
                link.click();

                // Clean up
                URL.revokeObjectURL(link.href);



            } catch (error) {
                console.error("Fetch error: ", error);
                setError(error.message || 'An error occurred');



            } finally {
                setLoading(false); // Hide loading state
            }
        }
        else {
            alert('select year and month first ')
        }

    };

    function convertToCSV(data) {
        const array = [Object.keys(data[0])].concat(data);

        return array.map(row => {
            return Object.values(row).map(value =>
                typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
            ).join(',');
        }).join('\n');
    }


    const columns = [
        {
            field: 'id',
            headerName: 'ID.',
            width: 50,
            // headerClassName: 'sticky-header',
            // cellClassName: 'sticky-cell',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <span>{params.row.id}</span>,
        },
        {
            field: 'name',
            headerName: 'Employee Name',
            width: 200, // Adjust width as needed
            // headerClassName: 'sticky-header',
            // cellClassName: 'sticky-cell',
            align: 'center',
            headerAlign: 'center',
        },


        { field: 'basic_total', headerName: 'Basic Salary', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'allowance', headerName: 'Allowance', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'deduction_monthly_total', headerName: 'Deduction', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'incentive', headerName: 'Incentive', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'gross_month_total_incentive', headerName: 'Gross Salary', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'net_total', headerName: 'Net Total', width: 125, align: 'center', headerAlign: 'center' },
        // { field: 'working_days', headerName: 'Working Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'absent_days', headerName: 'Absent Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'present_days', headerName: 'Present Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'totalExtraDays', headerName: 'Extra Days', width: 150, align: 'center', headerAlign: 'center' },

        // { field: 'break_violation', headerName: 'Break Violation', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'late_violation', headerName: 'Late Violation', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'leave_violation', headerName: 'Leave Violation', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'bank_name', headerName: 'Bank Name', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'account_no', headerName: 'Account No', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'IFSC_code', headerName: 'IFSC code', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'branch_name', headerName: 'Branch Name', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'PAN_no', headerName: 'PAN No', width: 150, align: 'center', headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            // headerClassName: 'sticky-header',
            // cellClassName: 'last-sticky-cell',

            renderCell: (params) => {
                const hasValidValues =
                    params.row.basic != 0 &&
                    params.row.allowance != 0 &&
                    params.row.deduction != 0;
                return (
                    <>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            className="bi bi-pencil-fill edit ebtn"
                            viewBox="0 0 16 16"
                            style={{ cursor: hasValidValues ? 'pointer' : 'not-allowed', marginRight: '10px', opacity: hasValidValues ? 1 : 0.5 }}
                            onClick={(e) => {
                                if (hasValidValues) {
                                    handleEditEmployee(params.row.id, params);
                                    handleShowModal();
                                }
                            }}
                        >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                            // style={{ cursor: 'pointer', marginRight: '10px' }}
                            style={{ cursor: hasValidValues ? 'pointer' : 'not-allowed', marginRight: '10px', opacity: hasValidValues ? 1 : 0.5 }}
                            onClick={(e) => {

                                if (hasValidValues) {
                                    handleShowViewModal(params, params.row.name);
                                }
                            }}
                        >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                        </svg>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            className="bi bi-trash3-fill delete delete-button"
                            viewBox="0 0 16 16"
                            style={{ cursor: 'pointer', marginRight: '10px' }}
                            onClick={() => openConfirmationModal(params.row._id)}
                        >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleClick(params.row, params)}
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            style={{ cursor: 'pointer', marginRight: '10px', color: 'black', fontWeight: 'bold' }}
                            className="feather feather-file">
                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                            <polyline points="13 2 13 9 20 9"></polyline>
                        </svg>
                    </>
                );
            },
        },
    ];

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        const payload = { ...editEmployeeData, ...formData };

        // Merge arrays for basic, allowence, and deduction
        ['basic', 'allowence', 'deduction'].forEach(key => {
            if (formData[key]) {
                payload[key] = formData[key];
            }
        });

        setLoading(true);
        try {
            const response = await update_salary_structure_setup(payload);
            console.log('Update successful:', response);
            if (response) {
                toast.success("Employee salary updated successfully", {
                    position: "top-center",
                    autoClose: 1000,
                });
                setIsChangeAble(true)
            }
            handleviewsalarystructuresetup(salaryForm.year, salaryForm.month);
            setSubmitted(!submitted);
            setFormData({});
        } catch (error) {
            console.error('Error updating salary structure:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        // without reload 
        // setSalaryForm({ ...salaryForm, [name]: value });

        // with current month 
        // setSalaryForm((prev) => ({ ...prev, [name]: value }));

        // with last selected month 
        setSalaryForm((prev) => {
            // Save selected values to local storage
            localStorage.setItem(name === 'year' ? 'selectedYear' : 'selectedMonth', value);
            return { ...prev, [name]: value };
        });
        console.log(salaryForm);
    };



    const handlePageSizeChange = (event) => {
        setpageSize(parseInt(event.target.value, 10));
    };

    const handlePageChange = (event) => {
        setPage(parseInt(event.target.value, 10));
        console.log(event.target.value)
    };


    // const handleInputChange = (e, key, index = null) => {
    //     const { name, value } = e.target;
    //     let updatedFormData = { ...formData };

    //     // Check if the field is within an array
    //     if (index !== null) {
    //         const [field, subIndex] = name.split('[');
    //         const subField = field.trim();
    //         const subFieldIndex = parseInt(subIndex.split(']')[0], 10);

    //         // Ensure the subField exists and is an array
    //         if (Array.isArray(updatedFormData[subField])) {
    //             updatedFormData[subField][subFieldIndex].monthly = value; // Update the specific field
    //         }
    //     } else {
    //         updatedFormData[key] = value; // Update top-level fields
    //     }

    //     setFormData(updatedFormData);
    //     calculateNetTotal(updatedFormData);
    // };


    useEffect(() => {
        console.log(editEmployeeData);
        if (editEmployeeData) {

            setFormData(editEmployeeData);
            console.log(editEmployeeData);

            const perDayBasic = editEmployeeData.basic[0].monthly / editEmployeeData.present_days;
            const basicAmount = editEmployeeData.basic[0].monthly;
            setPerDayBasic(perDayBasic); // Store per day basic in state
            setInitialBasic(basicAmount); // Store per day basic in state
            console.log(perDayBasic);
            sessionStorage.setItem('perdayBasic', perDayBasic);

            const updatedAllowances = editEmployeeData.allowence.map((allowance) => {
                if (allowance.name !== "Incentive") {
                    const perDayAllowance = allowance.monthly / editEmployeeData.present_days;
                    sessionStorage.setItem(`perDayAllowance_${allowance.name}`, perDayAllowance);
                    return {
                        ...allowance,
                        monthly: perDayAllowance * editEmployeeData.present_days // Update monthly amount
                    };
                }
                return allowance;
            });

            const updatedDeductions = editEmployeeData.deduction.map((deduction) => {
                const perDayDeduction = deduction.monthly / editEmployeeData.present_days;
                sessionStorage.setItem(`perDayDeduction_${deduction.name}`, perDayDeduction); // Add missing underscore
                return {
                    ...deduction,
                    monthly: perDayDeduction * editEmployeeData.present_days // Update monthly amount
                };
                return deduction;
            });

            setFormData((prevData) => ({
                ...prevData,
                allowence: updatedAllowances,
                deduction: updatedDeductions
            }));


            const combinedEditBasicAndAllowance = [
                ...editEmployeeData.basic,
                ...editEmployeeData.allowence
            ];

            console.log(combinedEditBasicAndAllowance);


            let totalGross = combinedEditBasicAndAllowance
                .filter(allowance => allowance.name !== "Incentive")
                .reduce((sum, item) => sum + item.monthly, 0);

            setEditCombineGrossData(combinedEditBasicAndAllowance)
            setEditGross(Number(totalGross))
            console.log(Number(totalGross));


        }

    }, [editEmployeeData]);

    // days to amount change working perfectly 
    const handleInputChange = (e, key, index = null) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData };
        let newUpdatedFormData = { ...updatedFormData };
        let newBasicAmount;
        let allowanceAmount;
        let deductionAmount;
        const [field, subIndex] = name.split('[');

        console.log(index);
        let updatedGrossData = [...editCombineGrossData];

        if (!Array.isArray(updatedGrossData)) {
            console.error("editCombineGrossData is not an array");
            return;
        }

        if (index !== null) {

            let changedValueType = editEmployeeData[key][index].type;
            let changedValueName = editEmployeeData[key][index].name;

            const presentDays = updatedFormData.present_days || 1; // Avoid division by 0
            const [field, subIndex] = name.split('[');
            const subField = field.trim();
            const subFieldIndex = parseInt(subIndex.split(']')[0], 10);

            if (key == 'basic') {
                setFlag(true)
                newBasicAmount = parseFloat(value) || 0;

                // Calculate the new per-day basic amount
                const newPerDayBasic = newBasicAmount / presentDays;
                updatedGrossData[0].monthly = (newBasicAmount);
                updatedGrossData[0].Annually = (newBasicAmount) * 12;
                console.log(updatedGrossData);

                sessionStorage.setItem('perdayBasic', JSON.stringify(newPerDayBasic));
                console.log(newBasicAmount, presentDays, newPerDayBasic);

                let totalGross = editCombineGrossData
                    .filter(allowance => allowance.name !== "Incentive")
                    .reduce((sum, item) => sum + item.monthly, 0);
                console.log(totalGross);

                // setEditCombineGrossData([...updatedGrossData]);
                setEditCombineGrossData([...updatedGrossData]);
                setEditGross(totalGross);
            }
            console.log(key);

            if (key == 'allowence') {
                setFlag(true)
                // Handle array updates for monthly values
                allowanceAmount = parseFloat(value) || 0;
                let newPerDayAllowance;

                console.log(updatedGrossData);


                updatedGrossData[index + 1].monthly = allowanceAmount;
                updatedGrossData[index + 1].Annually = (allowanceAmount) * 12;
                console.log(updatedGrossData);
                console.log(allowanceAmount, presentDays, newPerDayAllowance);
                let totalGross = editCombineGrossData
                    .filter(allowance => allowance.name !== "Incentive")
                    .reduce((sum, item) => sum + Number(item.monthly), 0);
                console.log(totalGross);
                setEditCombineGrossData([...updatedGrossData]);
                setEditGross(Number(totalGross))

                // // Calculate the new per-day basic amount
                newPerDayAllowance = allowanceAmount / presentDays; // Calculate per day allowance
                sessionStorage.setItem(`perDayAllowance_${updatedFormData[subField][subFieldIndex].name}`, JSON.stringify(newPerDayAllowance));
                console.log(`New per-day allowance for ${updatedFormData[subField][subFieldIndex].name}:`, newPerDayAllowance);
                updatedFormData[subField][subFieldIndex].monthly = allowanceAmount;
                console.log(allowanceAmount);

                if (newPerDayAllowance) {
                    setFlag(false)
                }

            }
            if (key == 'deduction') {

                let deductionAmount;
                if (flag == false) {
                    deductionAmount = parseFloat(value) || 0;
                }
                let newPerDayDeduction;

                console.log(changedValueName, changedValueType, editGross < 21000);
                if (changedValueName == 'ESI' && changedValueType == "Percentage" && editGross < 21000) {
                    console.log(deductionAmount);

                }

                // Calculate the new per-day basic amount
                newPerDayDeduction = deductionAmount / presentDays; // Calculate per day allowance
                sessionStorage.setItem(`perDayDeduction_${updatedFormData[subField][subFieldIndex].name}`, JSON.stringify(newPerDayDeduction));
                console.log(`New per-day deduction for ${updatedFormData[subField][subFieldIndex].name}:`, newPerDayDeduction);
                updatedFormData[subField][subFieldIndex].monthly = (deductionAmount);
                console.log(deductionAmount);

                if (newPerDayDeduction) {
                    setFlag(false)
                }
            }




            if (Array.isArray(updatedFormData[subField])) {
                if (updatedFormData[subField][subFieldIndex].name === "PF" && (value) > 1800) {
                    // Show toast error if PF exceeds 1800
                    toast.error("PF cannot exceed 1800", {
                        position: "top-center",
                        autoClose: 5000,
                    });
                    return;
                }
                updatedFormData[subField][subFieldIndex].monthly = (value);
            }
        }

        else {
            if (key == 'present_days') {
                setFlag(true)
                let presentDays = parseFloat(value) || 0;

                updatedFormData.present_days = presentDays
            }
            if (key == 'break_violation') {
                setFlag(true)
                let breakviolation = parseFloat(value) || 0;

                updatedFormData.break_violation = breakviolation
            }
            if (key == 'late_violation') {
                setFlag(true)
                let lateviolation = parseFloat(value) || 0;

                updatedFormData.late_violation = lateviolation
            }
        }


        setFormData(updatedFormData);
        console.log(formData);
        console.log(updatedFormData);

        setUpdatedFormData(newUpdatedFormData);
    };

    const calculateNetTotal = async (data) => {

        console.log(data);

        const payload = { ...editEmployeeData, ...formData };

        // Merge arrays for basic, allowence, and deduction
        ['basic', 'allowence', 'deduction'].forEach(key => {
            if (formData[key]) {
                payload[key] = formData[key];
            }
        });

        setLoading(true);
        try {
            const response = await edit_salary_struct(payload);
            console.log('Update successful:', response);
            if (response) {
                toast.success("Employee salary updated successfully", {
                    position: "top-center",
                    autoClose: 1000,
                });
                const updatedData = response.data[0].emp_new_salary; // Adjust according to your response structure
                setFormData(updatedData);
                setIsChangeAble(false)
            }
        } catch (error) {
            console.error('Error updating salary structure:', error);
        } finally {
            setLoading(false);
        }
    };





    // Rounding function
    const roundValue = (value, key) => {

        if (value == null || value == undefined) {
            return ''; // Handle null or undefined values
        }

        if (key == "Proff. Tax" && value > 200) {
            return 200;
        }
        if (key == "PF" && value > 1900) {
            return 1800;
        }

        const rounded = value > 0.5 ? Math.ceil(value) : Math.floor(value);
        return rounded % 1 == 0 ? rounded.toString() : rounded;

    };



    const handleviewsalarystructuresetup = useCallback(
        debounce(async (year, month) => {
            setLoading(true);
            setDataNotAvailable(false);
            try {
                const response = await viewsalarystructuresetuppaggination({
                    year: year,
                    month: month,
                    page: page,
                    pageSize: pageSize
                });
                console.log("API response:", response);



                if (response.success) {
                    if (response.data.length === 0) {
                        setDataNotAvailable(true);
                        setRows([]); // Set flag if no data is returned
                    } else {
                        setViewEmployees(response.data)
                        const fetchedRows = response.data.map((item, index) => {
                            // Process the data here
                            const basicAmt = Array.isArray(item.basic) ? item.basic.map((value) => value.monthly) :
                                (item.basic && item.basic.monthly ? [item.basic.monthly] : []);


                            let totalAllowances = 0;
                            if (Array.isArray(item.allowence)) {
                                totalAllowances = item.allowence
                                    .filter(allowance => allowance.name.toLowerCase() !== 'incentive') // Exclude incentives
                                    .reduce((sum, allowance) => sum + allowance.monthly, 0); // Sum up the monthly amounts
                            }

                            let totalGross = totalAllowances + basicAmt;

                            // Process allowances and deductions
                            let hraAmount = 0, conveyanceAmount = 0, medicalAmount = 0, incentiveAmount = 0;

                            if (Array.isArray(item.allowence)) {
                                item.allowence.forEach((allowance) => {
                                    switch (allowance.name.toLowerCase()) { // use toLowerCase() for case consistency
                                        case 'hra':
                                            hraAmount = allowance.monthly;
                                            break;
                                        case 'conv. all':
                                            conveyanceAmount = allowance.monthly;
                                            break;
                                        case 'driver all.':
                                            medicalAmount = allowance.monthly;
                                            break;
                                        case 'incentive':
                                            incentiveAmount = allowance.monthly;
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            }

                            let totalEarning = hraAmount + conveyanceAmount + medicalAmount + incentiveAmount;

                            let pfAmount = 0, proftaxAmount = 0, esicAmount = 0, loandeductionNew = 0;

                            if (Array.isArray(item.deduction)) {
                                item.deduction.forEach((deduction) => {
                                    switch (deduction.name.toLowerCase()) {
                                        case 'pf':
                                            pfAmount = deduction.monthly;
                                            break;
                                        case 'proff. tax':
                                            proftaxAmount = deduction.monthly;
                                            break;
                                        case 'esi':
                                            esicAmount = deduction.monthly;
                                            break;
                                        case 'loandeduction':
                                            loandeductionNew = deduction.monthly;
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            }

                            let totalDeductions = pfAmount + proftaxAmount + esicAmount + loandeductionNew;

                            return {
                                id: item._id || `${index + 1}`,
                                serial: index + 1,
                                name: item.name,
                                basic_total: roundValue(basicAmt[0]), // Apply rounding
                                gross_month_total_incentive: roundValue(item.gross_month_total_incentive), // Apply rounding
                                deduction_monthly_total: roundValue(item.deduction_monthly_total), // Apply rounding
                                incentive: roundValue(incentiveAmount), // Displaying monthly incentive value
                                basic: basicAmt.map(roundValue), // Apply rounding
                                allowance: roundValue(totalAllowances), // Apply rounding
                                // allowance: Array.isArray(item.allowence) ? item.allowence.map(a => roundValue(a.monthly)) : [], // Apply rounding
                                // deduction: Array.isArray(item.deduction) ? item.deduction.map(d => roundValue(d.monthly)) : [],
                                working_days: item.working_days,
                                absent_days: item.absent_days,
                                present_days: item.present_days,
                                totalExtraDays: item.totalExtraDays,
                                break_violation: roundValue(item.break_violation),
                                late_violation: roundValue(item.late_violation),
                                leave_violation: roundValue(item.leave_violation),
                                net_total: roundValue(item.net_total),
                                bank_name: item.bank_information[0]?.bank_name,
                                account_no: item.bank_information[0]?.account_no,
                                IFSC_code: item.bank_information[0]?.IFSC_code,
                                branch_name: item.bank_information[0]?.branch_name,
                                PAN_no: item.bank_information[0]?.PAN_no
                            };
                        });

                        setRows(fetchedRows);
                    }
                }
            } catch (error) {
                console.error("Error fetching salary structure setup:", error);
            } finally {
                setLoading(false);
            }
        }, 500),
        [page, pageSize]
    );


    useEffect(() => {
        setRows([]);
        setLoading(true);
        handleviewsalarystructuresetup(salaryForm.year, salaryForm.month);
    }, [salaryForm.year, salaryForm.month, handleviewsalarystructuresetup]);

    useEffect(() => {
        if (fromSalaryPDF) {
            // If navigating from SalaryPDF, use last selected values
            const savedYear = localStorage.getItem('selectedYear') || currentYear.toString();
            const savedMonth = localStorage.getItem('selectedMonth') || currentMonth;
            setSalaryForm({ year: savedYear, month: savedMonth });
        } else {
            // On initial load, use current values
            setSalaryForm({ year: currentYear.toString(), month: currentMonth });
            localStorage.setItem('selectedYear', currentYear.toString());
            localStorage.setItem('selectedMonth', currentMonth);
        }
    }, [fromSalaryPDF, currentYear, currentMonth]);

    // const renderForm = () => {
    //     console.log(editEmployeeData);

    //     if (!editEmployeeData) return null;

    //     const { allowence, deduction, leave_violation, net_total, name, working_days, absent_days, present_days, totalExtraDays } = editEmployeeData;

    //     // Function to capitalize the first letter of each word and remove underscores
    //     const formatKey = (key) =>
    //         key
    //             .split('_')
    //             .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //             .join(' ');

    //     // Filter out keys that should not be displayed
    //     const filteredKeys = Object.keys(editEmployeeData).filter((key) =>
    //         [
    //             "name",
    //             "working_days",
    //             'totalExtraDays',
    //             "absent_days",
    //             "present_days",
    //             "basic",
    //             "allowence",
    //             "deduction",
    //             "break_violation",
    //             "leave_violation",
    //             "late_violation",
    //             "net_total",
    //         ].includes(key)
    //     );

    //     // Check if "Incentive" exists in the allowence or deduction arrays
    //     const hasIncentives =
    //         (allowence || []).some((item) => item.name.toLowerCase() === "incentive") ||
    //         (deduction || []).some((item) => item.name.toLowerCase() === "incentive");

    //     return (
    //         <form>
    //             {filteredKeys.map((key) => {
    //                 const value = formData[key];
    //                 console.log(value);

    //                 const readOnlyFields = ['working_days', 'absent_days', 'totalExtraDays', 'net_total', 'leave_violation'];
    //                 const isReadOnly = readOnlyFields.includes(key);

    //                 if (!Array.isArray(value)) {

    //                     const displayValue = (key === "leave_violation" || key === "net_total") ? roundValue(value) : value;
    //                     return (
    //                         <Form.Group as={Row} className="mb-3" key={key}>
    //                             <Form.Label column sm="4">
    //                                 {formatKey(key)}:
    //                             </Form.Label>
    //                             <Col sm="8">
    //                                 <Form.Control
    //                                     type="text"
    //                                     name={key}
    //                                     value={displayValue}
    //                                     onChange={(e) => handleInputChange(e, key)}
    //                                     readOnly={isReadOnly}
    //                                 />
    //                             </Col>
    //                         </Form.Group>
    //                     );
    //                 }

    //                 return (
    //                     <div key={key}>
    //                         <h5 className="mt-4">{formatKey(key)}</h5>
    //                         {value.map((item, index) => (
    //                             <Form.Group as={Row} className="mb-3" key={`${key}-${index}`}>
    //                                 <Form.Label column sm="4">
    //                                     {item.name}:
    //                                 </Form.Label>
    //                                 <Col sm="8">
    //                                     <Form.Control
    //                                         type="text"
    //                                         name={`${key}[${index}].monthly`}
    //                                         value={roundValue(item.monthly, item.name)} // Apply roundValue here
    //                                         onChange={(e) => handleInputChange(e, key, index)}
    //                                     />
    //                                 </Col>
    //                             </Form.Group>
    //                         ))}
    //                     </div>
    //                 );
    //             })}

    //             <Button variant="primary" onClick={() => calculateNetTotal(updatedFormData)}>
    //                 Calculate Total
    //             </Button>
    //         </form>
    //     );
    // };


    const renderForm = () => {
        console.log(editEmployeeData);

        if (!editEmployeeData) return null;

        const { allowence, deduction, leave_violation, net_total, name, working_days, absent_days, present_days, totalExtraDays } = editEmployeeData;

        // Function to capitalize the first letter of each word and remove underscores
        const formatKey = (key) =>
            key
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

        // Filter out keys that should not be displayed
        const filteredKeys = Object.keys(editEmployeeData).filter((key) =>
            [
                "name",
                "working_days",
                'totalExtraDays',
                "absent_days",
                "present_days",
                "basic",
                "allowence",
                "deduction",
                "break_violation",
                "leave_violation",
                "late_violation",
                "net_total",
            ].includes(key)
        );

        // Check if "Incentive" exists in the allowence or deduction arrays
        const hasIncentives =
            (allowence || []).some((item) => item.name.toLowerCase() === "incentive") ||
            (deduction || []).some((item) => item.name.toLowerCase() === "incentive");

        return (
            <form>
                {filteredKeys.map((key) => {
                    const value = formData[key];
                    console.log(value);

                    const readOnlyFields = ['working_days', 'absent_days', 'totalExtraDays', 'net_total', 'leave_violation'];
                    // Determine if the field should be read-only
                    const isReadOnly = changable ? readOnlyFields.includes(key) : true;

                    if (!Array.isArray(value)) {
                        const displayValue = (key === "leave_violation" || key === "net_total") ? roundValue(value) : value;
                        return (
                            <Form.Group as={Row} className="mb-3" key={key}>
                                <Form.Label column sm="4">
                                    {formatKey(key)}:
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        value={displayValue}
                                        onChange={(e) => handleInputChange(e, key)}
                                        readOnly={isReadOnly}
                                    />
                                </Col>
                            </Form.Group>
                        );
                    }

                    return (
                        <div key={key}>
                            <h5 className="mt-4">{formatKey(key)}</h5>
                            {value.map((item, index) => (
                                <Form.Group as={Row} className="mb-3" key={`${key}-${index}`}>
                                    <Form.Label column sm="4">
                                        {item.name}:
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            name={`${key}[${index}].monthly`}
                                            value={roundValue(item.monthly, item.name)}
                                            onChange={(e) => handleInputChange(e, key, index)}
                                            readOnly={isReadOnly}
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                        </div>
                    );
                })}

                <Button variant="primary" onClick={() => calculateNetTotal(updatedFormData)}>
                    Calculate Total
                </Button>
            </form>
        );
    };



    const handleEditEmployee = async (id, params) => {
        setRowId(Number(id))
        console.log(params);

        setIsChangeAble(true)

        setLoading(true);
        try {
            const response = await editsalarystructuresetup({ year: `${salaryForm.year}`, month: `${salaryForm.month}`, name: `${params.row.name}` });
            console.log("edit API response:", response);


            if (response.success) {
                setEditEmployeeData(response.data.emp_new_salary)
                // setFormData({ ...formData, id: response.data.emp_new_salary._id, year: response.data.emp_new_salary.year, month: response.data.emp_new_salary.month })
                setShowModal(true);
                console.log(response.data.emp_new_salary);

            } else {
                console.error("API response was not successful");
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };


    const filterRows = () => {
        if (!searchText) {
            return Object.entries(employeeSalaryData).map(([id, data], index) => ({ id, serial: index + 1, ...data }));
        }
        return Object.entries(employeeSalaryData)
            .filter(([id, row]) =>
                Object.values(row).some(
                    (value) =>
                        typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
                )
            )
            .map(([id, data], index) => ({ id, serial: index + 1, ...data }));
    };

    const openConfirmationModal = (id) => {
        setDeleteEmployeeId(id);
        setShowConfirmation(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmation(false);
    };

    const handleDeleteEmployee = async (id) => {
        console.log(`Delete employee with ID: ${id}`);
        setShowConfirmation(false);
    };

    const openAddSalaryModal = () => {
        setShowAddSalaryModal(true);
    };

    const closeAddSalaryModal = () => {
        setShowAddSalaryModal(false);
        setSalaryForm({ month: '', year: '' });
    };

    const handleGenerateSalary = () => {
        const { year, month } = salaryForm;
        setSelectedYear(year);
        setSelectedMonth(month);
        fetchEmployeeSalaryData(year, month.toLowerCase());
        console.log('Generate staff salary with details:', salaryForm);
        closeAddSalaryModal();
    };


    const handleVerifyPassword = (e) => {
        const { value } = e.target;
        handleClosePasswordModal();
        if (value) {
            sessionStorage.setItem('adminUser', 'true');
        }
    };


    const fetchEmployeeSalaryData = async (year, month) => {
        try {
            setLoading(true);
            const salaryData = await fetchEmployeeSalaryCalculation(year, month);
            setEmployeeSalaryData(salaryData);
            console.log("salaryData", salaryData);

            if (salaryData) {
                toast.success(salaryData.message);
            }
        } catch (error) {
            console.error('Error fetching employee salary data:', error);
        } finally {
            setLoading(false);
        }
    };

    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const generateMonthOptions = () => {
        return monthNames.map((month, index) => ({
            value: month, // Using month name as value
            label: month, // Using month name as label
        }));
    };



    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => ({
            value: (currentYear + index).toString(),
            label: (currentYear + index).toString(),
        }));
        return years;
    };

    const handleNavigate = () => {
        navigate("/staffSalary");
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/employeesalary">Employee Salary</Link>
                            </li>
                            {/* <li className="breadcrumb-item active">List</li> */}
                        </ol>
                    </div>
                </div>

                <Card className="popup-card empSalary">
                    <Card.Body>
                        <div className="mb-4 border-bottom text-center">
                            <h3 className="mb-0 pb-3 border-bottom custome-btn">Employee Salary</h3>
                        </div>

                        <div className="row align-items-center">
                            {dataNotAvailable && (
                                <Alert variant="info">
                                    <strong>Note: </strong>No salary data has been generated for {salaryForm.month.charAt(0).toUpperCase() + salaryForm.month.slice(1)} {salaryForm.year}.
                                </Alert>
                            )}
                            <div className="col-md-5 mb-3">
                                <div className="btnw-100">
                                    <label>Monthly Salary Estimate:</label>
                                    <h4><span>₹</span>{calculatedTotalSalary}</h4>
                                </div>
                            </div>


                            <div className="col-md-7 mb-3"></div>
                            <div className="col-md-2 mb-3">
                                <button onClick={openAddSalaryModal} className="btn btn-primary w-100">Generate Salary</button>
                            </div>
                            {/* <div className="col-md-2 mb-3">
                                <Button variant="contained" className="btn btn-primary w-100" onClick={handleNavigate}>
                                    Add Staff Salary
                                </Button>
                            </div> */}





                            <div className="col-md-3 mb-3">
                                <Row>
                                    <Col md={6} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="year"
                                                // value={formData.year || ""}
                                                value={salaryForm.year}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleviewsalarystructuresetup(e)
                                                }}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="month"
                                                // value={formData.month || ""}
                                                value={salaryForm.month}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleviewsalarystructuresetup(e)
                                                }}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>
                                                        {month.charAt(0).toUpperCase() + month.slice(1)}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="col-md-3 mb-3">
                                <Button className="bg-success border-0" onClick={() => handleExportSalary()} style={{ whiteSpace: 'nowrap' }}>
                                    Export Data to Excel Sheet
                                </Button>
                            </div>

                            <div className="col-md-3 mb-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name='search'
                                        placeholder="Search"
                                        value={search}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="search-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12.5 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Employee Salary Info Monthly:</strong> {selectedDate && selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })} : salaryForm.month + salaryForm.year
                        </div> */}
                        <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Employee Salary Info Monthly: </strong>
                            {salaryForm.month && salaryForm.year
                                ? `${capitalizeFirstLetter(salaryForm.month)} ${salaryForm.year}`
                                : capitalizeFirstLetter(selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' }))}
                        </div>



                        {/* <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                ref={tableRef}
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                loading={loading}
                                getRowId={(row) => row.id}  // Make sure `id` is unique and present
                            />

                        </div> */}


                        {/* <div className="data-grid-container" style={customStyles.grid}>
                            <DataGrid
                                className="gridColor"
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                pagination
                                paginationMode="client"
                                getRowId={(row) => row.id}
                                components={{
                                    Pagination: () => (
                                        <div className="custom-select-container">
                                            <div>
                                                <label htmlFor="page-size">Rows per page: </label>
                                                <select
                                                    id="page-size"
                                                    value={pageSize}
                                                    onChange={handlePageSizeChange}
                                                >
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="page-number">Page: </label>
                                                <select
                                                    className="page-number"
                                                    id="page-number"
                                                    value={page}
                                                    onChange={handlePageChange}
                                                >
                                                    {[...Array(totalPages).keys()].map((pageNumber) => (
                                                        <option key={pageNumber} value={pageNumber + 1}>
                                                            {pageNumber + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ),
                                }}
                                componentsProps={{
                                    cell: { style: customStyles.cell },
                                    columnHeader: { style: customStyles.columnHeader },
                                }}
                                rowCount={count}
                                page={page - 1}
                                onPageChange={(newPage) => setPage(newPage + 1)}
                                rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                            />
                        </div> */}

                        {loading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="data-grid-container" style={customStyles.grid}>

                                <DataGrid
                                    className="gridColor"
                                    rows={rows} // The data array to be displayed
                                    columns={columns} // Your defined columns
                                    pageSize={pageSize} // Current page size
                                    pagination
                                    paginationMode="client"
                                    getRowId={(row) => row.id} // Use 'id' as the unique identifier
                                    components={{
                                        Pagination: () => (
                                            <div className="custom-select-container">
                                                <div>
                                                    <label htmlFor="page-size">Rows per page: </label>
                                                    <select
                                                        id="page-size"
                                                        value={pageSize}
                                                        onChange={handlePageSizeChange} // Handle page size changes
                                                    >
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="page-number">Page: </label>
                                                    <select
                                                        className="page-number"
                                                        id="page-number"
                                                        value={page}
                                                        onChange={handlePageChange} // Handle page changes
                                                    >
                                                        {[...Array(totalPages).keys()].map((pageNumber) => (
                                                            <option key={pageNumber} value={pageNumber + 1}>
                                                                {pageNumber + 1}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        ),
                                        NoRowsOverlay: () => (
                                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                                No Salary available
                                            </div>
                                        ),
                                    }}
                                    componentsProps={{
                                        cell: { style: customStyles.cell }, // Your custom cell styles
                                        columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
                                    }}
                                    rowCount={rows.length} // Use the length of rows to show total records
                                    page={page - 1} // Current page (0-based index)
                                    onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                                    rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                                />
                            </div>
                        )}

                    </Card.Body>
                </Card>

                <Modal show={showConfirmation} onHide={closeConfirmationModal}>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title style={{ color: 'white' }}>Delete Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeConfirmationModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteEmployee(deleteEmployeeId)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAddSalaryModal} onHide={closeAddSalaryModal} >
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title style={{ color: 'white' }}>Add Staff Salary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="year">Year:</label>
                            <select
                                id="year"
                                className="form-control"
                                value={salaryForm.year}
                                onChange={(e) => setSalaryForm({ ...salaryForm, year: e.target.value })}
                            >
                                <option value="">Select Year</option>
                                {generateYearOptions().map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="month">Month:</label>
                            <select
                                id="month"
                                className="form-control"
                                value={salaryForm.month}
                                onChange={(e) => setSalaryForm({ ...salaryForm, month: e.target.value })}
                            >
                                <option value="">Select Month</option>
                                {generateMonthOptions().map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeAddSalaryModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleGenerateSalary}>
                            Generate
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* password modal  */}
                <Modal show={showPasswordModal}  >
                    <Modal.Header className="custom-modal-header">
                        <Modal.Title style={{ color: 'white' }}>Verify Admin Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <div className="mb-3">
                                <label htmlFor="admin_password" style={{ color: 'black' }} className="form-label fw-bold">
                                    Enter Admin Password
                                </label>
                                <input
                                    type="text"
                                    className="form-control custom-input"
                                    id="admin_password"
                                    name="admin_password"
                                    onChange={handleVerifyPassword}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={handleVerifyPassword}>
                            Verify
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModal} onHide={handleCloseModal} className="custom-modal" size="xl">
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title className="custom-modal-title" style={{ color: 'white' }}>Edit Employee Details</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body className="custom-modal-body">
                            {renderForm()}
                            <p style={{ fontWeight: 'bold' }}>Gross:- Basic + Allowance without incentives</p>
                            <p style={{ fontWeight: 'bold' }}>PF:- 12% up to 1800/- on combined basic and conveyance</p>
                            <p style={{ fontWeight: 'bold' }}>ESI:- 0.75% on Gross less than 21000/-</p>
                            <p style={{ fontWeight: 'bold' }}>Proff Tax:- 200/- on Gross greater than 12000/-</p>
                        </Modal.Body>
                        <Modal.Footer className="custom-modal-footer">
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={async (e) => {
                                    e.preventDefault(); // Prevent default form submission if applicable
                                    await handleSubmit();
                                    handleCloseModal();
                                }}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>


                <Modal show={showViewModal} onHide={handleCloseViewModal} className="custom-modal" size="xl">
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title className="custom-modal-title" style={{ color: 'white' }}>View Employee Details</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body className="custom-modal-body">
                            {selectedEmployee ? (
                                <>
                                    <h5>Employee Information</h5>
                                    <table className="table table-bordered text-center">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: '25%' }}>Name: {selectedEmployee.name}</th>
                                                <th style={{ width: '25%' }}>Working Days: {selectedEmployee.working_days}</th>
                                                <th style={{ width: '25%' }}>Present Days: {selectedEmployee.present_days}</th>
                                                <th style={{ width: '25%' }}>Absent Days: {selectedEmployee.absent_days}</th>
                                            </tr>

                                        </tbody>
                                    </table>

                                    {selectedEmployee.bank_information && selectedEmployee.bank_information.length > 0 && (
                                        <>
                                            <h5>Bank Details</h5>
                                            <table className="table table-bordered text-center">
                                                <tbody>
                                                    {selectedEmployee.bank_information.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td style={{ width: '20%' }}>IFSC Code: {item.IFSC_code || ""}</td>
                                                                <td style={{ width: '20%' }}>PAN No: {item.PAN_no || ""}</td>
                                                                <td style={{ width: '20%' }}>Account No: {item.account_no || ""}</td>
                                                                <td style={{ width: '20%' }}>Bank Name: {item.bank_name || ""}</td>
                                                                <td style={{ width: '20%' }}>Branch Name: {item.branch_name || ""}</td>
                                                            </tr>

                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>
                                    )}


                                    <h5>Earnings</h5>
                                    <table className="table table-bordered text-center">
                                        <tbody>
                                            {selectedEmployee.basic.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: '33%' }}>{item.name}</td>
                                                    <td style={{ width: '33%' }}>{roundValue(item.monthly)}</td> {/* Apply rounding if needed */}
                                                    <td style={{ width: '33%' }}>{roundValue(item.Annually)}</td> {/* Apply rounding if needed */}
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tbody>
                                            {selectedEmployee.allowence.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: '33%' }}>{item.name}</td>
                                                    <td style={{ width: '33%' }}>{roundValue(item.monthly)}</td>
                                                    <td style={{ width: '33%' }}>{roundValue(item.Annually)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>



                                    <h5>Deductions</h5>
                                    <table className="table table-bordered text-center ">
                                        <tbody>
                                            {selectedEmployee.deduction.map((item, index) => (
                                                <tr key={index} >
                                                    <td style={{ width: '33%' }}>{item.name}</td>
                                                    <td style={{ width: '33%' }}>{roundValue(item.monthly)}</td>
                                                    <td style={{ width: '33%' }}>{roundValue(item.Annually)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <h5>Total</h5>
                                    <table className="table table-bordered text-center red-row">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '50%' }}>Gross Total: {roundValue(viewGross)} </td>
                                                <td style={{ width: '50%' }}>Net Total: {roundValue(selectedEmployee.net_total)}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p style={{ fontWeight: 'bold' }}>Gross:- Basic + Allowance without incentives</p>
                                    <p style={{ fontWeight: 'bold' }}>PF:- 12% up to 1800/- on combined basic and conveyance</p>
                                    <p style={{ fontWeight: 'bold' }}>ESI:- 0.75% on Gross less than 21000/-</p>
                                    <p style={{ fontWeight: 'bold' }}>Proff Tax:- 200/- on Gross more than 12000/-</p>

                                </>
                            ) : (
                                <p>No employee data available.</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer className="custom-modal-footer">
                            <Button variant="secondary" onClick={handleCloseViewModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>



            </div>
        </React.Fragment>
    );

};

export default Employeesalary;